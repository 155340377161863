'use strict';

define('vb/private/vx/v1/extensionRegistry',[
  'vb/private/vx/baseExtensionRegistry',
  'vb/private/vx/v1/extension',
], (BaseExtensionRegistry, ExtensionV1) => {
  const serviceRegex = /^self\/services\/([\w_$-]*)\/openapi[\w.]*\.json$/;
  const catalogRegex = /^self\/services\/catalog.json$/;

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class ExtensionRegistry extends BaseExtensionRegistry {
    static get extensionManagerVersion() {
      return 'v1';
    }

    /**
     * The regex to find openapi3 files; in V1 Extension Registry format, they lived in "self".
     * The () group is used to capture the service name from the path.
     * @return {RegExp}
     */
    static get serviceRegex() {
      return serviceRegex;
    }

    /**
     * The regular expresion to find a catalog in a list of extension files
     * @return {RegExp}
     */
    static get catalogRegex() {
      return catalogRegex;
    }

    createExtension(def) {
      return new ExtensionV1(def, this);
    }

    initialize() {
      return super.initialize();
    }

    /**
     * Initiate loading the manifest by building the URL and doing the fetch call immediately
     * without waiting on the result.
     * @return {Promise}
     */
    initiateLoadManifest() {
      return this.applicationConfigCallback()
        .then((definition) => this.getExtensionAdapter(this.constructor.registryUrl).then((adapter) => {
          this.adapter = adapter;
          return adapter.initiateLoadManifest(definition.id);
        }));
    }

    /**
     * Returns the cached loadmanifest promise
     * @return {Promise}
     */
    getLoadManifestPromise() {
      this.loadManifestPromise = this.loadManifestPromise || this.loadManifest()
        .catch((err) => {
          // Swallow the error so that it doesn't break the application, but no extension will be loaded
          this.log.error('Error loading extension registry, running baseline application', err);
          return {};
        });

      return this.loadManifestPromise;
    }

    /**
     * For v1, the base prefix for container ('ui'), is not in the base path
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForUi(path, container) {
      return this.getBasePath(path, container);
    }

    /**
     * For v1, the base path for layout is the path prefixed by the extension id
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForLayout(path, container) {
      return this.getBasePath(path, container);
    }

    /**
     * Create a dependency graph from the given extension as the root.
     *
     * @param {Array<Object>}extensions all extension
     * @param {Object} extension the root extension
     * @return {Array<Object>}
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    getExtensionDependencies(extensions, extension) {
      const dependencies = [];

      // TODO: Return a flat list for V1 for now. Should we get this to work for V1?
      extensions.forEach((ext) => {
        const dependency = {
          extension: ext,
          dependencies: [],
        };

        dependencies.push(dependency);
      });

      return dependencies;
    }
  }

  return ExtensionRegistry;
});


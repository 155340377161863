/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/action/actionChainUtils',[
  'ojs/ojcontext',
  'vb/action/action',
  'vb/helpers/actionHelpers',
  'vb/private/stateManagement/scope',
  'vb/private/stateManagement/stateUtils',
  'vb/private/utils',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/constants',
  'vb/private/debug/actionChainDebugStream',
  'vbc/private/monitorOptions',
], (ojContext, Action, ActionHelpers, Scope, StateUtils, Utils, Log, LogConfig, Constants,
  ActionChainDebugStream, MonitorOptions) => {
  const logger = Log.getLogger('/vb/action/actionChain', [
    // Register custom loggers
    {
      name: 'startChain',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.actionChainStart,
    },
    {
      name: 'endChain',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.actionChainEnd,
    },
  ]);

  // bufp-17642: list of dollar-variables to skip when injecting the context for the current page into the
  // context for the chain. These should only be available to the event listener, and passed to chains if needed.
  // note: this '$current' is the one from the JET event listener, and NOT the one from forEachAction.
  const EXCLUDED_CHAIN_SCOPE_VARS = [
    Constants.ContextName.EVENT,
    Constants.ContextName.BINDING_CONTEXT,
    '$current', // not using constant, so it is not mistaken for the 'forEachAction' one
    Constants.ContextName.PREVIOUS,
  ];

  class ActionMonitorOptions extends MonitorOptions {
    constructor(actionId, module, action) {
      const message = `${module} ${action.logLabel}`;
      super('action', message);
      this.addTags(() => ({
        actionId,
        actionType: module,
      }));
    }
  }

  class ActionError extends Error {
    constructor(message, payload) {
      super(message);
      this.payload = payload;
    }
  }

  /**
   * An ActionChain is a graph of one or more Actions that are executed using the supplied
   * context.
   */
  class ActionChainUtils {
    /**
     * Extract the chain id from the given id which can be prefixed, e.g., application:fooAction or flow:fooAction.
     *
     * @param id the id, e.g., fooChain, application:fooChain or flow:fooChain
     * @returns {*|string}
     */
    static extractChainId(id) {
      const idParts = id.split(':');
      return idParts.length === 1 ? idParts[0] : idParts[1];
    }

    /**
     * Starts an action chain and returns the promise that will resolve to the outcome of the last
     * executed action of the chain.
     *
     * @param chainId The chain id
     * @param params Set of parameters to match the variables of the action chain
     * @param scopes Scopes in context for the chain
     * @param context Internal objects like actions, application, and flow needed by a builtin action
     * @param isJsChain true if this is a JS action chain
     * @param rethrowError if true, rethrow error instead of firing a notification event
     * @returns {Promise.<*>}
     */
    static startChain(chainId, params = {}, context, scopes, isJsChain, rethrowError) {
      const executionContext = this.createExecutionContext(chainId, context, scopes, isJsChain);

      return Promise.resolve()
        .then(() => {
          if (!chainId) {
            throw new Error('Required \'id\' parameter missing for starting action chain.');
          }

          // handle application, flow or page level action chains
          // Resolve the scope (application, flow, page) from the actionId if one exist
          // and get the action metadata.
          return StateUtils.resolveChain(chainId, context.container.scopeResolver, isJsChain)
            .then((chainSource) => {
              if (!chainSource) {
                throw new Error(`Action chain ${chainId} does not exist.`);
              }

              return this.createChain(chainId, chainSource)
                .then((actionChain) => {
                  const inputParamValues = StateUtils.deepEval(params, scopes);

                  // add a busy state to JET's busy context which is used by webdriverjs tests to wait for
                  // activities in the page to quiet down
                  const busyContext = ojContext.getPageContext()
                    .getBusyContext();
                  const busyStateResolver = busyContext.addBusyState({ description: chainId });

                  const debugStream = this.getDebugStream(executionContext);

                  debugStream.start(inputParamValues);
                  return actionChain.run(executionContext, inputParamValues)
                    .then((returnValue) => {
                      debugStream.end(returnValue);
                      return returnValue;
                    })
                    .finally(() => {
                      // run any registered 'finally' cleanup tasks
                      this.runFinallyCallbacks(executionContext);
                      busyStateResolver();
                    });
                });
            });
        })
        .catch((error) => this.handleError(error, executionContext, isJsChain, rethrowError));
    }

    /**
     * If chainSource is a class for a code-based action chain, an instance of it will be instantiated and
     * returned. Otherwise, an instance of JSON-based action chain will be returned.
     *
     * @param chainId the chain id
     * @param chainSource a code-base action chain class or a JSON descriptor
     * @returns {Promise<Object>}
     */
    static createChain(chainId, chainSource) {
      return Promise.resolve()
        .then(() => {
          if (typeof chainSource === 'function') {
            const ChainClass = chainSource;
            return new ChainClass();
          }

          return this.loadJsonActionChain()
            .then((JsonActionChain) => new JsonActionChain(this.extractChainId(chainId), chainSource));
        });
    }

    /**
     * Loads the JSON-based action chain class.
     *
     * @returns {*}
     */
    static loadJsonActionChain() {
      if (!this.loadJsonActionChainPromise) {
        this.loadJsonActionChainPromise = Utils.getResource('vb/private/action/actionChain');
      }

      return this.loadJsonActionChainPromise;
    }

    /**
     * Creates the execution context used to invoke an action chain.
     *
     * @param chainId chain id used to locate the action chain
     * @param context contains information about the calling containers
     * @param callingContexts the available scopes from the calling container
     * @param isJsChain true if this is a JS action chain
     * @returns {Object}
     */
    static createExecutionContext(chainId, context = {}, callingContexts = {}, isJsChain) {
      const executionContext = {};
      const callingContainer = context.container;
      const callingScopeResolver = callingContainer && callingContainer.scopeResolver;
      let targetContainer;
      let scopes = callingContexts;

      // make sure we get the correct scopes in which the chain will be executed
      if (chainId && callingScopeResolver) { // make sure we don't break tests
        // use the scopeResolver to look up the container in which the chain is defined
        targetContainer = StateUtils.resolveContainer(chainId, callingScopeResolver);

        // get the available scopes from the container if it's different from the calling container
        // NOTE: Since this breaks existing FA apps, the decision is made to only enable this
        // new behavior for app uis to allow more time for app developers to fix their apps.
        const isAppUi = targetContainer.package || (targetContainer.base && targetContainer.base.package);
        if (!isAppUi) {
          targetContainer = callingContainer;
        }

        // If target container is different from the calling container, we need to use the scopes
        // from the target container.
        if (targetContainer !== callingContainer) {
          scopes = targetContainer.getAvailableContexts();
        }
      }

      const debugStream = new ActionChainDebugStream(this.extractChainId(chainId), executionContext);

      const internalContext = Object.assign({},
        context,
        {
          chainId,
          callingContexts,
          targetContainer,
          internalContext: {},
          finallyCallbacks: [],
          debugStream,
        });
      executionContext[Constants.CHAIN_INTERNAL_CONTEXT] = internalContext;

      // cannot use Object.assign, need to copy getters; some values might not have been created yet (page.pageScope)
      Object.getOwnPropertyNames(scopes).forEach((key) => {
        // skip ones that are specific to the eventListener declaration, and should not be available in the chain
        const descriptor = Object.getOwnPropertyDescriptor(scopes, key);

        if (EXCLUDED_CHAIN_SCOPE_VARS.indexOf(key) === -1) {
          // guard against scope contexts that attempt to override our chain variables
          if (!Object.prototype.hasOwnProperty.call(executionContext, key)) {
            Object.defineProperty(executionContext, key, descriptor);
          }
        } else {
          //  don't expose, but make it available to actions that need it
          Object.defineProperty(internalContext.internalContext, key, descriptor);
        }
      });

      // for JS chains, sanitize the execution context and remove shortcuts, so we don't expose unnecessary
      // information
      if (isJsChain) {
        ['$chains', '$imports', '$listeners', '$constants', '$enums', '$functions', '$metadata',
          '$translations', '$variables'].forEach((scopeName) => {
          delete executionContext[scopeName];
        });

        // return a read-only version of the execution context that prevents JS chains from directly
        // modifying VB variables
        return ActionChainUtils.preventWrites(executionContext);
      }

      return executionContext;
    }

    /**
     * Invokes the action identified by actionModuleId.
     *
     * @param actionModuleId the id for the action module
     * @param executionContext execution context containing all the information necessary to invoke the action
     * @param params action parameters
     * @param options
     * @returns {Promise}
     */
    static runAction(actionModuleId, executionContext, params, options = {}) {
      return this.loadActionModule(actionModuleId)
        .then((NewAction) => {
          const id = options.id || options.alias;
          const internalContext = this.getInternalContext(executionContext);
          const { chainId } = internalContext;

          // create and configure the action
          const actionConfig = this.getActionConfig(id, internalContext);
          const action = new NewAction(id, id, actionConfig);

          this.addHelpersToAction(action, internalContext);

          this.addContextToAction(actionModuleId, action, executionContext);

          const debugStream = this.getDebugStream(executionContext);
          const mo = new ActionMonitorOptions(id, actionModuleId, action);

          logger.info('Chain', chainId, 'starting action', action.logLabel,
            'with parameters:', params);

          // eslint-disable-next-line arrow-body-style
          return logger.monitor(mo, (totalStepTime) => debugStream.actionStart(action, params)
            .then(() => action.start(params))
            .then((oc) => {
              // TODO: implement logging
              let outcome = oc;

              // For callChainAction, if the called chain is a JS chain (determined by $params.chain), the result
              // is simply a value, so we need to wrap the result in a success outcome so it can be handled
              // like any other action.
              if (actionModuleId === 'vb/action/builtin/callChainAction' && params.chain) {
                outcome = Action.createSuccessOutcome(oc);
              }

              debugStream.actionEnd(action, outcome);

              const result = this.handleActionOutcome(actionModuleId, outcome);

              logger.info('Chain', chainId, 'ending action', action.logLabel,
                'with result', result, totalStepTime());

              return result;
            })
            .catch((e) => {
              logger.error('Chain', chainId, 'action step', action.logLabel, 'failed.', e,
                totalStepTime(e));
              throw e;
            }));
        });
    }

    /**
     * Unwraps an action outcome. For a success outcome, the corresponding result is returned. For a failure
     * outcome, information is extracted from the outcome and an instance of ActionError is thrown.
     *
     * @param actionModuleId the id for the action module
     * @param outcome the action outcome to unwrap
     * @returns {*}
     */
    static handleActionOutcome(actionModuleId, outcome) {
      if (outcome.name === 'success') {
        return outcome.result;
      }

      const { result } = outcome;
      const { message, error, payload } = result;

      throw error || new ActionError(message.summary, payload);
    }

    /**
     * For JS based action chain, fire a notification event to report the error by default.
     *
     * @param error the error to be reported
     * @param context the context necessary to run fireNotificationEventAction
     * @param isJsChain true for JS action chain
     * @param rethrowError if true, rethrow the error instance of firing a notification event
     * @returns {Promise}
     */
    static handleError(error, context, isJsChain, rethrowError) {
      if (isJsChain && !rethrowError) {
        return this.runAction('vb/action/builtin/fireNotificationEventAction',
          context, {
            summary: error.message,
            type: 'error',
          }, { id: 'fireNotificationEvent' });
      }

      // otherwise, rethrow the error
      throw error;
    }

    /**
     * Load the action module specified by actionModuleId. This method will return the cached module if it
     * has already been loaded.
     *
     * @param actionModuleId the id for the action module to load
     */
    static loadActionModule(actionModuleId) {
      this.actionModules = this.actionModules || {};

      return Promise.resolve().then(() => {
        const module = this.actionModules[actionModuleId];

        if (!module) {
          return Utils.getResource(actionModuleId)
            // TODO: turn off caching for now to unblock preflight
            // this.actionModules[actionModuleId] = actionModule;
            .then((actionModule) => actionModule);
        }

        return module;
      });
    }

    /**
     * Add to an action the helper functions that require the context . The helper
     * functions are needed to implement user-specified actions.
     *
     * @param action
     * @param context
     */
    static addHelpersToAction(action, context) {
      // inject the helpers on the action
      Object.defineProperty(action, 'helpers', {
        value: new ActionHelpers(context),
      });
    }

    /**
     * Certain actions need more context. Instead of exposing an interface API, we instead look
     * for specific actions and inject the context.
     *
     * The advantage of this approach is that user-specified actions cannot configure their
     * actions to gain access to the context - something we want to avoid in general.
     *
     * @param actionType The module name of the action
     * @param action The newly created action
     * @param executionContext context necessary to invoke the action
     */
    static addContextToAction(actionType, action, executionContext) {
      const internalContext = this.getInternalContext(executionContext);
      const writableContext = this.getWritableExecutionContext(executionContext)

      if (actionType.startsWith('vb/action/builtin/')) {
        switch (actionType.substr(18)) {
          case 'assignVariablesAction':
          case 'resetVariablesAction': {
            action.setAvailableContext(writableContext);
            break;
          }

          case 'callChainAction': {
            const availableContextsClone = writableContext.clone();
            action.setContext(availableContextsClone, internalContext);
            break;
          }

          case 'restAction':
          case 'fireNotificationEventAction':
          case 'loginAction':
          case 'logoutAction':
          case 'editorUrlAction':
          case 'restartApplicationAction': {
            action.setContext(internalContext);
            break;
          }

          case 'fireCustomEventAction': {
            action.setInternalContext(internalContext, internalContext.internalContext);
            break;
          }

          default:
            break;
        }
      }

      // inject the container lifecycle state without making the container available to the action
      if (internalContext && internalContext.container) {
        Object.defineProperty(action, 'containerLifecycleState', {
          get: () => internalContext.container.lifecycleState,
        });
      }
    }

    /**
     * Returns the internal context object nested in the internal context of the executionContext
     *
     * @param executionContext execution context for the action chain
     * @returns {*}
     */
    static getInternalContext(executionContext) {
      return executionContext[Constants.CHAIN_INTERNAL_CONTEXT];
    }

    /**
     * Return a writable version of the executionContext.
     *
     * @param executionContext
     * @returns {*}
     */
    static getWritableExecutionContext(executionContext) {
      return this.getInternalContext(executionContext).writableContext || executionContext;
    }

    /**
     * Returns the debug stream from the internal context of the executionContext
     *
     * @param executionContext execution context for the action chain
     * @returns {*}
     */
    static getDebugStream(executionContext) {
      return this.getInternalContext(executionContext).debugStream;
    }

    /**
     * Get the configuration object to pass to the constructor. Currently only contains the 'registrar',
     * to allow action to register callbacks
     *
     * @param actionId
     * @param executionContext
     * @returns {Object}
     */
    static getActionConfig(actionId, executionContext) {
      // provide a narrow interface for the action to call
      return {
        registrar: {
          setFinallyCallback: (name, callback) => {
            this.setFinallyCallback(executionContext, actionId, name, callback);
          },
        },
      };
    }

    /**
     * Actions that are granted access to the actionChain can register a callback called when the chain is complete.
     * use an array; callbacks will be called in registration order
     * the callback can optionally return a Promise.
     *
     * @param actionInstanceId
     * @param name
     * @param callbackFnc
     */
    static setFinallyCallback(executionContext, actionInstanceId, name, callbackFnc) {
      const internalContext = this.getInternalContext(executionContext);
      const { finallyCallbacks } = internalContext;
      const existing = finallyCallbacks.find((callback) => callback.id === actionInstanceId);

      // replace the existing one - each action only gets one
      if (existing) {
        existing.name = name;
        existing.fnc = callbackFnc;
      } else {
        finallyCallbacks.push({ id: actionInstanceId, name, fnc: callbackFnc });
      }
    }

    /**
     * Run any registered callbacks
     *
     * If a callback returns a Promise, it must resolve/reject before the next callback is called.
     * A rejected Promise does not stop callback processing.
     */
    static runFinallyCallbacks(executionContext) {
      const internalContext = this.getInternalContext(executionContext);
      const { finallyCallbacks } = internalContext;

      let promise = Promise.resolve(); // initial promise
      finallyCallbacks.forEach((callback) => {
        promise = promise.then(() => {
          if (typeof callback.fnc === 'function') {
            try {
              logger.info('calling', callback.name, 'callback for action', callback.id);
              // allow callback fnc to optionally return a Promise
              return Promise.resolve(callback.fnc());
            } catch (e) {
              logger.error('error in Action callback for', callback.id, ':', callback.name, e);
            }
          }
          return null; // not used;
        });
      });
    }

    /**
     * Wrap executionContext in a Proxy that prevents direct modification of scopes. Use
     * getWritableExecutionContext to get a writable version of the executionContext.
     *
     * @param executionContext the execution context to wrap
     * @returns {*}
     */
    static preventWrites(executionContext) {
      const scopeProxies = {};
      const internalContext = this.getInternalContext(executionContext);

      // save the un-proxied version in the internal context so it can be easily retrieved when needed
      internalContext.writableContext = executionContext;

      const listener = {
        get(target, property) {
          const realValue = target[property];

          // don't proxy the internal context since it's only accessible internally
          if (realValue === internalContext) {
            return realValue;
          }

          // cache proxies for $application, $page, etc
          if (target === executionContext) {
            // eslint-disable-next-line no-use-before-define
            scopeProxies[property] = scopeProxies[property] || new Proxy(realValue, listener);
            return scopeProxies[property];
          }

          const config = Object.getOwnPropertyDescriptor(target, property);
          if (config && config.configurable === false && config.writable === false) {
            return realValue;
          }

          if (Utils.isObjectOrArray(realValue) && Utils.isPrototypeOfObject(realValue)) {
            // eslint-disable-next-line no-use-before-define
            return new Proxy(realValue, listener);
          }

          return realValue;
        },
        set() {
          throw new Error('Cannot directly update variables. Use Actions.assignVariable instead.');
        },
      };

      return new Proxy(executionContext, listener);
    }
  }

  return ActionChainUtils;
});


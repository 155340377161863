/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/translations/bundleV2Definition',[
  'urijs/URI',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/pathHandler',
  'vb/private/utils',
  'vb/private/translations/bundleV2Extension',
], (
  URI,
  Constants,
  Log,
  PathHandler,
  Utils,
  BundleV2Extension,
) => {
  const logger = Log.getLogger('/vb/private/translations/bundleV2Definition');

  // Remove translations/self/ from the start of bundle path when finding matching -x
  const translationsSelfRegex = /^(translations\/self\/)(.*)$/;
  // This matches src/vb/private/helpers/runtimeEnvironment.js
  const badStringUrlSegmentRegex = /\/(undefined|null)\//gi;

  /**
   * Determine if the path starts with a '/'
   * @param path
   */
  function isRootRelative(path) {
    return path.indexOf('/') === 0;
  }

  /* eslint-disable max-len */
  /**
   * Utility class to handle the import path scheme for V2 translations.
   * See src/vb/private/pathHandler.js
   * BundleV2Definition has simpler requirements for a valid path, but which are hard to represent in the existing
   * PathHandler options.
   *
   * Paths cannot start with a '.'
   * If a path starts with a '/', the location of the resource is relative to the root of the extension or app
   * If a path doesn't start with a '/', the location of the resource is relative to '/translations/self/' at the root
   * of the extension, or '/resources/translations/' at the root of the app.
   * Paths can be absolute, which means they have a hostname.
   * Reference:
   * https://confluence.oraclecorp.com/confluence/pages/viewpage.action?pageId=1477883242&focusedCommentId=2976505802#JetV2TranslationsforBaseApps&ExtensionsforFA-Definition.1
   * https://confluence.oraclecorp.com/confluence/display/ABCS/Referring+to+components+or+modules+in+an+extension+or+an+App+UI
   */
  /* eslint-enable max-len */
  class V2PathHandler {
    /**
     *
     * @param translationsPath
     * @param bundlePath
     * @param baseUrl
     */
    constructor(translationsPath, bundlePath, baseUrl = '') {
      this.translationsPath = translationsPath;
      this.bundlePath = bundlePath;
      this.baseUrl = baseUrl;
      this.normalizedBundlePath = undefined;
    }

    /**
     * Allow neither "./somepath" nor "../somepath".
     * @returns {boolean}
     */
    isAllowed() {
      return !PathHandler.startsWithDot(this.bundlePath);
    }

    /**
     * Normalizes the path.
     * If path begins with '/', it is relative to the extension root or app root. It is expected that these paths start
     * with this.translationsPath.  The initial '/' is removed.
     * If path doesn't begin with '/', it is relative to this.translationsPath, which is prepended to the path.
     *
     * @returns {string}
     */
    getNormalizedBundlePath() {
      if (this.normalizedBundlePath === undefined) {
        if (isRootRelative(this.bundlePath)) {
          this.normalizedBundlePath = this.bundlePath.substring(1);
        } else {
          this.normalizedBundlePath = `${this.translationsPath}${this.bundlePath}`;
        }
      }
      return this.normalizedBundlePath;
    }

    /**
     * Resolve the path.
     * See src/vb/private/pathHandler.js
     * BundleV2Definition has simpler requirements for a valid path, but which are hard to represent in the existing
     * PathHandler options.
     * Path cannot begin with a '.'
     * If path begins with '/', it is relative to the extension root or app root. It is expected that these paths start
     * with '/translations/self/' (or '/resources/translations/' for an app)
     * If path doesn't begin with '/', it is relative to '/translations/self' in its extension or
     * '/resources/translations/' in the app.
     * The container path will be appended if there is one, and the path isn't absolute.
     *
     * @returns {string}
     */
    getResolvedPath() {
      if (!this.resolvedPath) {
        let path;
        // Absolute URL, use it
        if (Utils.isAbsoluteUrl(this.bundlePath)) {
          path = this.bundlePath;
          this.normalizedBundlePath = null; // (not undefined)
        } else {
          // Find the path relative to the extension or app root
          const normalizedBundlePath = this.getNormalizedBundlePath();
          if (this.baseUrl) {
            path = `${this.baseUrl}/${normalizedBundlePath}`;
          } else {
            path = normalizedBundlePath;
          }
        }

        // Normalize the path and use it
        this.resolvedPath = URI(path).normalizePath().toString();
      }
      return this.resolvedPath;
    }
  }

  /**
   * BundleV2Definition
   *
   * differences between this, and the original BundleDefinition
   *
   * - relative-to-container paths (starting with "./") are not supported; all paths are relative to the application,
   *   its extension, or absolute
   *
   * - path templates are not supported. this was never really used, but officially dropping support in new bundles.
   *
   * @todo: as of 09/29/2020, JET is not generating this format yet: JET-39285
   * simple example:
   *   define(() => ({
   *     "greeting": function (p){return "Hello, "+p["person"];},
   *     "heading": function (p){return "This is "+p["0"];},
   *   });
   *
   */
  class BundleV2Definition {
    /**
     * represents one translation bundle
     * @param application {object}
     * @param name {string}
     * @param path {string}
     * @param metadata {object} expressions are not evaluated
     * @param extension {object}
     */
    constructor(application, name, path, metadata = {}, extension = null) {
      this.application = application;
      this.name = name;
      this.path = path;
      this.extensionId = extension ? extension.id : Constants.ExtensionFolders.BASE;
      this.declaration = Object.assign({}, metadata);
      this.default = metadata.default || false;

      // allow neither "./somepath" nor "../somepath".
      if (this.extensionId === Constants.ExtensionFolders.BASE) {
        this.pathHandler = new V2PathHandler('resources/translations/', this.path);
      } else {
        this.pathHandler = new V2PathHandler('translations/self/', this.path, extension.baseUrl);
      }

      this.map = {}; // these will come directly from the JET-generated string bundle and extensions
    }

    static get extensionClass() {
      return BundleV2Extension;
    }

    /**
     * Whether or not the path is allowed
     * allow neither "./somepath" nor "../somepath".
     * @returns {boolean}
     */
    isAllowed() {
      return this.pathHandler.isAllowed();
    }

    /**
     * Resolve the path
     * @returns {string}
     */
    getResolvedPath() {
      return this.pathHandler.getResolvedPath();
    }

    /**
     */
    load(runtimeEnvironment) {
      if (!this.loadPromise) {
        let bundleFunctions = null;
        // Resolve the path
        const resolvedPath = this.getResolvedPath();
        this.loadPromise = runtimeEnvironment.getV2Strings(resolvedPath, this.declaration)
          .then((v2Objects) => {
            // Copy the value of functions, but still use the actual reference in the 'map' property.
            // This is because DT will replace v2Objects.functions with a proxy to allow updates to string bundles
            // in preview without requiring reloading of the bundle, or the defining container.
            bundleFunctions = v2Objects.functions;

            Object.defineProperty(this, 'map', {
              get: () => v2Objects.functions || {},
            });

            const normalizedBundlePath = this.pathHandler.getNormalizedBundlePath();
            // No normalized path (Absolute).   Can't derive an extension from its path
            if (!normalizedBundlePath) {
              return null;
            }

            // Derive path to find matching translation extensions (-x) in extension packages.
            // In base, normalizedBundlePath may be
            //   resources/translations/bundle-i18n
            // It should remain as-is, and ExtensionRegistry will append 'translations/base/', to get
            //   'translations/base/resources/translations/bundle-i18n'
            //
            // In an extension (e.g. 'extId'), normalizedBundlePath may be
            //   translations/self/bundle-i18n
            // It should be transformed to
            //   bundle-i18n
            // and ExtensionRegistry will append 'translations/<extId>/' based on this Bundle Definition, to get
            //  'translations/<extId>/bundle-i18n'
            let comparePath = normalizedBundlePath.replace(badStringUrlSegmentRegex, '/');
            if (this.extensionId !== Constants.ExtensionFolders.BASE) {
              comparePath = comparePath.replace(translationsSelfRegex, '$2');
            }

            return this.application.extensionRegistry.loadTranslationExtensions(comparePath, this);
          })
          .then((extensions) => {
            // If we have extensions, copy their overridden string functions
            if (bundleFunctions && extensions && extensions.length) {
              extensions.forEach((extension) => {
                const extensionFunctions = extension.getStringFunctions();
                Object.keys(extensionFunctions)
                  .forEach((key) => {
                    // eslint-disable-next-line no-param-reassign
                    bundleFunctions[key] = extensionFunctions[key];
                  });
              });
            }
            return this;
          })
          .catch((error) => {
            // If we loaded the bundle but not the extensions report that the extension load failed but return this
            // Otherwise, report that the bundle load failed.
            if (bundleFunctions) {
              logger.error(`Failed to load v2 bundle extensions for ${this.path}`, error);
              return this;
            }

            logger.error(`Failed to load v2 bundle ${this.path}`, error);
            return null;
          });
      }
      return this.loadPromise;
    }

    /**
     * Load the V2 Bundle Definition, if it hasn't already been loaded.
     *
     * @param application {object}
     * @param extension {object}
     * @param name {string}
     * @param path {string}
     * @param decl {object}
     */
    static getBundleV2Definition(application, extension, name, path, decl) {
      const extensionId = extension ? extension.id : Constants.ExtensionFolders.BASE;
      let extensionMap = BundleV2Definition.extensions[extensionId];
      if (!extensionMap) {
        extensionMap = {};
        BundleV2Definition.extensions[extensionId] = extensionMap;
      }
      let bundleDefinition = extensionMap[name];
      if (!bundleDefinition) {
        bundleDefinition = new BundleV2Definition(application, name, path, decl, extension);
        extensionMap[name] = bundleDefinition;
      }
      return bundleDefinition;
    }

    /**
     * Test helper
     * Reset cached information
     */
    static reset() {
      BundleV2Definition.extensions = {};
    }
  }

  BundleV2Definition.extensions = {};

  return BundleV2Definition;
});


'use strict';

define('vb/private/translations/bundlesModel',[
  'vb/private/constants',
  'vb/private/log',
  'vb/private/translations/bundleDefinition',
  'vb/private/translations/bundleV2Definition',
  'vb/binding/expression',
  'ojs/ojconfig',
], (
  Constants,
  Log,
  BundleDefinition,
  BundleV2Definition,
  Expression,
  ojConfig,
) => {
  const logger = Log.getLogger('vb/private/translations/bundlesModel');

  // we use the convention that V2 files are named "*-i18n".
  // requireJS requires the .js when the path includes protocol, and the file is not in a bundle.
  // Otherwise, the JS must be omitted.
  const REGEXP_V2 = /-i18n(\.js)?$/;

  /**
   *
   */
  class BundlesModel {
    /**
     * Create a model for BundleDefinitions.
     * Falsy paths should be ignored/skipped (VBS-2097)
     *
     * @param runtimeEnv the RuntimeEnvironment
     * @param referencePath the directory of the referring container
     * @param options {Object}
     * @param options.initParams {Object}
     * @param options.allowSelfRelative {boolean}
     * @param options.isUnrestrictedRelative {boolean}
     * @param extension {object} the extension containing this BundlesModel (or null for 'base')
     * @private
     */
    constructor(runtimeEnv, referencePath = '', options = {}, extension = null) {
      this.log = logger;
      this.relativePath = referencePath;
      this.runtimeEnvironment = runtimeEnv;
      this.options = options;
      this.extension = extension;

      this.bundleDefinitions = {};
      this.bundleV2Definitions = {};
      this.extensionsBundleV2Definitions = {};

      this.bundleMap = {};
      this.bundleV2Map = {};
      this.extensionsBundleMap = {};
    }

    /**
     * Evaluate the translation definition's path, using initParams passed in the BundlesModel's options
     * @param {Object} declaration declaration
     * @returns {string}
     * @private
     */
    evaluateTranslationPath(declaration) {
      return declaration && declaration.path && Expression
        .getEvaluatedSafe(declaration.path, {
          [Constants.ContextName.INIT_PARAMS]: this.options.initParams || {},
        });
    }

    /**
     * @private
     */
    addBundleV1Definitions(declarations) {
      // using JET to get locale
      const locale = ojConfig.getLocale();
      Object.entries(declarations)
        .forEach(([name, decl]) => {
          const path = this.evaluateTranslationPath(decl);
          if (!path) {
            logger.error(`No path declared for translation bundle ${name}`);
          } else if (BundlesModel.isV2(path)) {
            logger.error(`Not a v1 translation bundle ${name}`);
          } else {
            // eslint-disable-next-line max-len
            this.bundleDefinitions[name] = new BundleDefinition(name, path, decl, locale, this.relativePath, this.options);
          }
        });
    }

    /**
     * @private
     */
    addBundleV2Definitions(extId, extension, declarations) {
      Object.entries(declarations)
        .forEach(([name, decl]) => {
          const path = this.evaluateTranslationPath(decl);
          if (!path) {
            logger.error(`No path declared for translation bundle ${name}`);
          } else if (!BundlesModel.isV2(path)) {
            logger.error(`Not a v2 translation bundle ${name}`);
          } else if (extId === 'self') {
            // eslint-disable-next-line max-len
            this.bundleV2Definitions[name] = BundleV2Definition.getBundleV2Definition(BundlesModel.application, this.extension, name, path, decl);
          } else {
            if (!this.extensionsBundleV2Definitions[extId]) {
              this.extensionsBundleV2Definitions[extId] = {};
            }
            // eslint-disable-next-line max-len
            this.extensionsBundleV2Definitions[extId][name] = BundleV2Definition.getBundleV2Definition(BundlesModel.application, extension, name, path, decl);
          }
        });
    }

    /**
     * path (file name) is used to determine which bundle version we have ('*-i18n')
     * @param path
     * @returns {*|boolean}
     * @private
     */
    static isV2(path) {
      return path && REGEXP_V2.test(path);
    }

    /**
     * load all bundles, and make a map of name - to - string map
     * @returns {Promise<BundlesModel>}
     */
    load() {
      if (!this.loadPromise) {
        // Load the bundleV1Definitions, make a 'master' map of bundle name -> bundle strings
        const promises = Object.values(this.bundleDefinitions)
          .filter((def) => def.isAllowed())
          .map((def) => def.load(this.runtimeEnvironment)
            .then((bundle) => {
              // Make sure each bundle was actually loaded
              if (bundle) {
                this.bundleMap[bundle.name] = bundle.map;
              }
            }));

        // Load the bundleV2Definitions, add to the 'master' map of bundle name -> bundle strings
        promises.push(...Object.values(this.bundleV2Definitions)
          .filter((def) => def.isAllowed())
          .map((def) => def.load(this.runtimeEnvironment)
            .then((bundle) => {
              // Make sure each bundle was actually loaded
              if (bundle) {
                this.bundleV2Map[bundle.name] = bundle.map;
              }
            })));

        // Load the extensionsBundleV2Definitions, make a 'master' map of extensionId -> (bundle name -> bundle strings)
        Object.entries(this.extensionsBundleV2Definitions)
          .forEach(([extId, defs]) => {
            promises.push(...Object.values(defs)
              .filter((def) => def.isAllowed())
              .map((def) => def.load(this.runtimeEnvironment)
                .then((bundle) => {
                  // Make sure each bundle was actually loaded
                  if (bundle) {
                    if (!this.extensionsBundleMap[extId]) {
                      this.extensionsBundleMap[extId] = {};
                    }
                    this.extensionsBundleMap[extId][bundle.name] = bundle.map;
                  }
                })));
          });

        this.loadPromise = Promise.all(promises)
          .then(() => this);
      }

      return this.loadPromise;
    }

    /**
     * @returns {{}}
     */
    getStringMap() {
      return this.bundleMap;
    }

    /**
     * Get the (V1) BundleDefinition with name
     * @param name {string} name of the BundleDefinition
     * @returns {BundleDefinition}
     */
    getBundleDefinition(name) {
      return this.bundleDefinitions[name];
    }

    /**
     * @returns {{}}
     */
    getV2StringMap() {
      return this.bundleV2Map;
    }

    /**
     * Get the (V2) Bundle String map for the extension
     * @param extId {string}
     * @returns {BundleDefinition}
     */
    getExtensionV2StringMap(extId) {
      return this.extensionsBundleMap[extId];
    }

    /**
     * Load an array of (non-null) BundleDefinition.
     * Falsy paths should be ignored/skipped (VBS-2097)
     *
     * @param runtimeEnv RuntimeEnvironment
     * @param metadata the "translations" or "imports" : "translations" configuration metadata
     * @param referencePath the directory of the referring container
     * @param options {Object}
     * @param options.initParams {Object}
     * @param options.allowSelfRelative {boolean}
     * @param options.isUnrestrictedRelative {boolean}
     * @param extension {object} the extension containing this BundlesModel (or null for 'base')
     * @returns {Promise<BundlesModel>}
     */
    static loadBundlesModel(runtimeEnv, metadata = {}, referencePath = '', options = {}, extension = null) {
      const bundlesModel = new BundlesModel(runtimeEnv, referencePath, options, extension);

      // Has V1 translations declaration
      // "translations": {
      //   "<bundleName>": {
      //     "path": "<bundlePath>"
      //   }
      // }
      if (metadata.translations) {
        bundlesModel.addBundleV1Definitions(metadata.translations);
      }

      // Has V2 translations declaration (1)
      // "imports": {
      //   "translations": {
      //     "self": [<bundleName>,...],
      //     "<extId>": [<bundleName>,...],
      //     "base": [<bundleName>,...]
      //   }
      // }
      // Or, if only using bundles in self, declaration (2)
      // "imports": {
      //   "translations: [<bundleName>,...]
      // }
      //
      // Assemble the corresponding bundle name/path information from the translation-config declaration
      // "translations": {
      //   "<bundleName>": {
      //     "path": "<bundlePath>"
      //   }
      // }
      if (metadata.imports && metadata.imports.translations) {
        const selfId = extension ? extension.id : Constants.ExtensionFolders.BASE;
        const translations = metadata.imports.translations;

        // Get list of extension ids that have bundleNames that we are interested in.
        // Map self to this extension id
        const extIds = Object.keys(translations)
          .map((extId) => ((extId === 'self') ? selfId : extId));

        // Get the bundle paths for each extension we are interested in (including this extension)
        const extsBundleDeclarations = BundlesModel.getExtensionsBundleDeclarations(runtimeEnv, extIds);

        // remap selfId declarations to 'self'
        const selfBundleDeclaration = extsBundleDeclarations[selfId];
        if (selfBundleDeclaration) {
          delete extsBundleDeclarations[selfId];
          extsBundleDeclarations.self = selfBundleDeclaration;
        }

        // Get the bundle declarations for the extensions and add them to the bundle model
        // We extract just the bundle declarations listed in imports.translations.  There could be more bundle
        // declarations in an extension, but aren't used by this BundlesModel
        const promises = [];
        Object.entries(translations)
          .forEach(([extId, bundleNames]) => {
            promises.push(extsBundleDeclarations[extId]
              .then((extBundleDeclarations) => {
                const bundleDeclarations = {};
                bundleNames.forEach((bundleName) => {
                  bundleDeclarations[bundleName] = extBundleDeclarations.translations[bundleName];
                });
                bundlesModel.addBundleV2Definitions(extId, extBundleDeclarations.extension, bundleDeclarations);
              })
              .catch((e) => {
                if (extId === 'self') {
                  logger.error(`Failed to load bundle declarations for ${extId})`, e);
                } else {
                  logger.error(`Failed to load bundle declarations for ${extId} (referenced in ${selfId})`, e);
                }
              }));
          });

        // After all the bundle declarations have been resolved, load the model.
        return Promise.all(promises)
          .then(() => bundlesModel.load());
      }

      return bundlesModel.load();
    }

    /**
     * Load the bundles name/declaration information from the translations-config in the extensions (including 'base')
     *
     * @param {Object} runtimeEnv
     * @param {Array<String>} extIds array of extension id or 'base'
     * @return Promise<object.<string, object.<string, object>>> Map of extensionId to Map of bundle name/declaration
     * @private
     */
    static getExtensionsBundleDeclarations(runtimeEnv, extIds) {
      const bundleDeclarations = {};

      extIds.forEach((extId) => {
        // See if we've already located the bundles declaration for each extension (and 'base').
        // If not, load the translations configuration
        let extensionBundleDeclarations = BundlesModel.extensionsBundleDeclarations[extId];
        if (!extensionBundleDeclarations) {
          let translationsConfig;
          let extension = null;
          if (extId === Constants.ExtensionFolders.BASE) {
            // Load the translations-config for the base app
            translationsConfig = runtimeEnv.getTextResource('resources/translations/translations-config.json');
          } else {
            // Load the translations-config for the extension
            translationsConfig = BundlesModel.getExtensions()
              .then((extensions) => {
                extension = extensions[extId];
                // Make sure the extension is initialized, so its require mappings are set.
                return extension.init()
                  // eslint-disable-next-line max-len
                  .then(() => runtimeEnv.getExtensionTextResource(`${extension.baseUrl}translations/translations-config.json`));
              });
          }

          // Extract the translations map of bundleName:bundlePath from the translations-config
          extensionBundleDeclarations = translationsConfig
            .then((config) => ({
              translations: JSON.parse(config).translations,
              extension,
            }))
            .catch((err) => {
              logger.error(`Failed to load translations-config for ${extId}`, err);
              return { translations: [] };
            });

          BundlesModel.extensionsBundleDeclarations[extId] = extensionBundleDeclarations;
        }

        bundleDeclarations[extId] = extensionBundleDeclarations;
      });

      return bundleDeclarations;
    }

    /**
     * Get a Promise to a map of extensions that have translations/translations-config.json
     * @returns {Promise<Map<string, object>>}
     */
    static getExtensions() {
      if (!BundlesModel.extensions) {
        BundlesModel.extensions = BundlesModel.application.extensionRegistry.getTranslations();
      }
      return BundlesModel.extensions;
    }

    /**
     * Test helper
     * Reset cached information
     */
    static reset() {
      BundlesModel.application = null;
      BundlesModel.extensionsBundleDeclarations = {};
      BundlesModel.extensions = null;
      BundleV2Definition.reset();
    }
  }

  /**
   * Reference to Application, so all Bundles can have access.
   * This is assigned in Application.load() (vb/private/stateManagement/applicationClass.js)
   */
  BundlesModel.application = null;

  /**
   * Map of extensionId to Map of bundle name/declaration
   * @type {Object.<string, Map<string, object>>} Map of extensionId to Map of bundle name/declaration
   */
  BundlesModel.extensionsBundleDeclarations = {};

  /**
   * Map of extensionId to extension that contains translation-config.json
   * @type {Promise<Map<string, object>>}
   */
  BundlesModel.extensions = null;

  return BundlesModel;
});


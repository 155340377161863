'use strict';

define('vb/private/stateManagement/appPackage',[
  'vb/errors/httpError',
  'vb/helpers/mixin',
  'vb/private/constants',
  'vb/private/stateManagement/appPackageExtension',
  'vb/private/stateManagement/containerRjsInitMixin',
  'vb/private/stateManagement/packageFlow',
  'vb/private/stateManagement/context/appPackageContext',
  'vb/private/utils',
  'vbsw/private/serviceWorkerManager',
], (HttpError, Mixin, Constants, AppPackageExtension, ContainerRjsInitMixin, PackageFlow, AppPackageContext,
  Utils, ServiceWorkerManager) => {
  /**
   * AppPackage class
   */
  class AppPackage extends Mixin(PackageFlow).with(ContainerRjsInitMixin) {
    /**
     * AppPackage constructor
     *
     * @param  {Extension}  extension the extension that contain this App UI
     * @param  {AppUiInfo}  appUiInfo the metadata for this App UI
     * @param  {Page}       page      the page that contain this App UI
     */
    constructor(extension, appUiInfo, page, className = 'AppPackage') {
      const id = appUiInfo.id;
      super(id, page, `${Constants.DefaultPaths.APPLICATIONS}${id}/`, className);

      // Override value defined in Container
      this._extension = extension;

      /**
       * @type {AppUiInfo}
       */
      this._appUiInfo = appUiInfo;
    }

    /**
     * The name is always app, app.json, app.js
     * Override value defined in Container
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
      return 'app';
    }

    /**
     * @type {AppPackage}
     */
    get package() {
      return this;
    }

    /**
     * @type {AppUiInfo}
     */
    get appUiInfo() {
      return this._appUiInfo;
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * appPackage we want to use the same event prefix as application
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'application';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getAppUiDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getAppUiFunctions';
    }

    static get extensionClass() {
      return AppPackageExtension;
    }

    /**
     * returns the AppPackageContext constructor used to create the '$' expression context
     * @type {AppPackageContext}
     */
    static get ContextType() {
      return AppPackageContext;
    }

    /**
     * Enables implicit mapping of the resources folder for App UI.
     *
     * @type {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    get implicitResourceFolder() {
      return true;
    }

    /**
     * Override the flow createService to define the services from the right place.
     * @return {Promise}
     */
    createServices() {
      return Promise.resolve()
        .then(() => {
          // App UI services are the services defined in the extension containing
          // this App UI.
          this.services = this.application.extensions[this.extensionId].services;
        });
    }

    /**
     * @type {string}
     */
    get fullName() {
      return this.name;
    }

    /**
     * Use the App UI urlId for the id in the URL
     * Override the value in Container
     * @type {!String}
     */
    get urlId() {
      return this.appUiInfo.urlId || super.urlId;
    }

    /**
     * @return {Boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    isNavigable() {
      // App UIs are always navigable
      return true;
    }

    enter() {
      // Assign the $global.currentAppUi variable
      // Uses the variable setValueInternal because it's a readonly variable and the regular
      // assignment will fail.
      const currentPageVar = this.application.scope.getVariable(Constants.GLOBAL_CURRENT_APPUI_VARIABLE,
        Constants.VariableNamespace.BUILTIN);
      currentPageVar.setValueInternal(this.appUiInfo);

      return super.enter();
    }

    /**
     * Generate a unique name to be used for the scope.
     * The name is unique and has the name of the class and the path to make it easier to
     * find the scope owner when debugging.
     *
     * @return {String} a new scope name
     */
    getNewScopeName() {
      return `${this.className}/${this.id}`;
    }

    initDefault(definition) {
      const def = definition;

      return super.initDefault(def);
    }

    // eslint-disable-next-line class-methods-use-this
    defineCurrentPageBuiltinVariable() {
      return null;
    }

    // eslint-disable-next-line class-methods-use-this
    updateCurrentPageVariable() {
      // no-op
    }

    /**
     * Overridden to support loading offline handler defined in an app ui.
     *
     * @returns {*}
     */
    load() {
      return super.load()
        .then(() => {
          const swMgrInstance = ServiceWorkerManager.getInstance();

          // uninstall existing offline handler if any
          return swMgrInstance.uninstallOfflineHandler()
            .then(() => this.getOfflineHandlerUrl())
            .then((offlineHandlerUrl) => {
              if (offlineHandlerUrl) {
                return swMgrInstance.installOfflineHandler(offlineHandlerUrl);
              }

              return undefined;
            });
        });
    }

    /**
     * Returns the requirejs path for the class in which the offline handler is defined. If the offline
     * handler is defined in the app.js, the path for app.js is returned. Otherwise, call
     * RuntimeEnvironment.getOfflineHandlerUrl to give DT a chance to install its own offline handler
     * which is used for caching data for performance optimization purpose.
     *
     * @returns {Promise<String>}
     */
    getOfflineHandlerUrl() {
      return Promise.resolve().then(() => {
        // If createOfflineHandler function is defined in app.js, return the resource path for app.js.
        if (this.functions && typeof this.functions.createOfflineHandler === 'function') {
          return this.getResourcePath();
        }

        // Otherwise, give DT a chance to install its own offline handler for caching data.
        return Utils.getRuntimeEnvironment().then((re) => re.getOfflineHandlerUrl());
      });
    }

    /**
     * App UI have the ability to skip their root page when the defaultFlow property
     * is in the definition instead of defaultPage
     *
     * @return {Promise}
     */
    processDefaultPage() {
      return Promise.resolve().then(() => {
        const flowId = this.definition.defaultFlow;
        // If defaulFlow is defined, do not process the default page,
        // just set the state of the router and return
        if (flowId) {
          this.router.defaultStateId = flowId;
          return undefined;
        }

        // Otherwise just proceed
        return super.processDefaultPage();
      });
    }

    /**
     * Retrieve the cached instance of the nested container.
     * @param  {String} id the id of the page to retrieve
     * @return {Object} the page instance
     */
    getContainer(id) {
      return this.flows[id] || super.getContainer(id);
    }

    loadContainer(id, navContext) {
      if (navContext && navContext.isCancelled()) {
        return Promise.resolve();
      }

      const flowId = this.definition.defaultFlow;
      if (flowId) {
        return this.loadFlowFromId(id, navContext);
      }

      return super.loadContainer(id, navContext);
    }

    loadFlow() {
      // Loads the extension bundle first
      return this.extension.init().then(() => super.loadFlow());
    }

    getScopeResolverMap() {
      return {
        [Constants.APP_UI_PREFIX]: this,
        [Constants.GLOBAL_PREFIX]: this.application,
      };
    }
  }

  return AppPackage;
});


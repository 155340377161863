'use strict';

define('vb/private/translations/bundleDefinition',[
  'vb/private/log',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/pathHandler',
  'vb/private/services/uriTemplate',
  'ojs/ojtranslation',
], (Log, Utils, Constants, PathHandler, UriTemplate, ojTranslation) => {
  const logger = Log.getLogger('/vb/private/translations/bundleDefinition');
  /**
   * BundleDefinition
   */
  class BundleDefinition {
    /**
     * represents one translation bundle
     * @param name
     * @param evaluatedPath {string}
     * @param metadata {object} expressions are not evaluated
     * @param locale
     * @param relativePath
     * @param options
     */
    constructor(name, evaluatedPath, metadata, locale, relativePath = '', options = {}) {
      const replacementValues = options.replacementValues || {};

      // 'unrestrictedRelative' means, you can reach outside of your current folder (only app-flow)
      const isUnrestrictedRelative = options.isUnrestrictedRelative || false;
      // allowSelfRelative means, let the container JSON use "./" to mean 'relative to the same folder.
      const allowSelfRelative = (options.allowSelfRelative !== false); // default is true

      const template = new UriTemplate(evaluatedPath || '', undefined, true); // no param defs, and skip adding queries

      const replacements = Object.assign({ 'locale-name': locale }, replacementValues);
      const path = template.replace(replacements);

      this.log = logger;
      this.name = name;
      this.default = metadata.default || false;
      this.pathHandler = new PathHandler(path, relativePath,
        { allowParent: isUnrestrictedRelative, allowSelfRelative });
      this.map = null;
    }

    /**
     * don't allow use of '..' in path outside of the Application declaration
     * @returns {boolean}
     */
    isAllowed() {
      return this.pathHandler.isAllowed();
    }

    /**
     */
    load(runtimeEnvironment) {
      // return `ojL10n!${this.uri.toString()}`;
      let path = this.pathHandler.getResolvedPath();

      // workaround for JET requiring an '/nls' in the path
      if (BundleDefinition.useWorkaroundNlsMappingForFA(path)) {
        path = BundleDefinition.workaroundNlsMapping(path);
      }

      return runtimeEnvironment.getLocaleBundle(path)
        .then((stringMap) => {
          this.map = stringMap;
          return this;
        });
    }

    /**
     *
     * @returns {*}
     */
    format(key, ...params) {
      return BundleDefinition.format(this.map, key, ...params);
    }

    /**
     * This is a workaround for the FA use-case.
     * VB runtime used to use requirejs.toUrl() to do its paths mapping resolution BEFORE loading bundles using
     * the ojL10n plugin.  But that didn't work when we started to bundle applications, because toUrl() returns
     * the path to the BUNDLE, and not the mapped module.
     *
     * So, we removed the use of requirejs.toUrl(), but that broke existing FA apps, because they included the
     * /nls/ segment in the mapping, BUT the JET plugin requires the /nls/ segment be in the path passed to the plugin.
     *
     * In other words, this wont work:
     * requirejs.config({ paths: { myPath: 'resources/nls/translations' } });
     *
     * require(['ojL10n!myPath'], (bundle) => // this throws an exception because the /nls/ isn't in the path
     *
     * So this workaround will basically do what we used to do - return the result of the requirejs.toUrl().
     * But this assumes ONLY the first segment of the bundle path is mapped, which is the FA use-case.
     *
     * In other words, this does NOT  handle this case: {paths: 'some/map': 'somepath/nls'})
     *
     * But generally, we need to document that developers should NOT include the /nls in the mapping.
     */

    /**
     * if its FA and not bundled, or if the path doesn't have '/nls', use the hack
     * @returns {boolean}
     */
    static useWorkaroundNlsMappingForFA(path) {
      const mappingParts = requirejs.toUrl('app-flow').split(Constants.PATH_SEPARATOR);

      // 'bundles' would be the second-to-last segment
      const isBundled = mappingParts.length > 1 && mappingParts[mappingParts.length - 2] === 'bundles';
      const isFA = !!window.faConfig || !!window.FAEndPoints;

      const missingNls = (path.indexOf('/nls') === -1);
      if (missingNls) {
        logger.warn(`translation bundle paths without "\\nls" are deprecated, and must be updated: ${path}`);
      }

      return (isFA && !isBundled) || missingNls;
    }

    /**
     * expand the url mapping before handing the path to the JET translation bundle
     * @todo: remove this eventually, after FA updates their bundle paths
     * @param path
     * @returns {*}
     */
    static workaroundNlsMapping(path) {
      let newPath = path;
      const parts = path.split(Constants.PATH_SEPARATOR);

      // assume the first segment is the 'paths' map key
      const mapkey = parts[0];

      // need something that would not be a bundle file name, so that when we call requirejs.toUrl(),
      // we do not get the bundle path.
      const uniqueSuffix = '__un1que__';

      const mappedPath = requirejs.toUrl(`${mapkey}/${uniqueSuffix}`);

      parts.shift(); // remove the first segment
      newPath = mappedPath.replace(uniqueSuffix, parts.join(Constants.PATH_SEPARATOR));

      // BUFP-31158: if the mapkey isn't actually a bundle mapping, just return the original
      if (mappedPath === `${requirejs.toUrl('')}${mapkey}/${uniqueSuffix}`) {
        return path;
      }

      // need to add/remove the .js based on whether the url is absolute
      if (Utils.isAbsolutePath(newPath)) {
        if (!newPath.endsWith('.js')) {
          newPath = `${newPath}.js`;
        }
      } else if (newPath.endsWith('.js')) {
        newPath = newPath.substring(0, newPath.length - 3);
      }
      // FA paths have a lot of double-slashes, and i think it confuses the requireJS cache when
      // loading the same file with paths that differ only with slashes
      newPath = newPath.replace(/(:\/\/)|(\/)+/g, '$1$2');

      return newPath;
    }

    /**
     * find the string for the given key in the given (l10n) bundle, and format any string replacements.
     *
     * ex: "{{ $application.translations.format('mybundle', 'titlekey', {appname: 'text'}) }}"
     *
     * @param bundle L10n bundle (not Bundle definition object).
     * @param key
     * @param params and object with named substitutions
     * @returns {string|null}
     */
    static format(bundle, key, ...params) {
      // first, try the key as-is
      let str = bundle[key];

      // else, try to split it
      if (!str) {
        const keys = key ? key.split('.') : [];
        if (keys.length > 1) {
          let value = bundle;

          for (let index = 0; index < keys.length && value; index += 1) {
            // if we have a key like a.b.c
            value = value[keys[index]];
          }

          str = value || null;
        }
      }
      // fallback to the key
      if (!str) {
        str = key;
      }

      return ojTranslation.applyParameters(str, ...params);
    }
  }

  return BundleDefinition;
});


'use strict';

/**
 * Do not statically require Router. this is used by runtimeEnvironment, and must not load JET until needed.
 * One reason is, the translation bundle loads are funnelled through runtimeEnvironment,
 * and the (requireJS) configuration for bundles must be done before JET is loaded.
 * If anything in runtimeEnvironment loads JET through a module dependency,
 * it will be loaded before the bootstrap has had a chance to initialize ConfigLoader (which config the bundles).
 */
define('vb/private/services/servicesLoader',[
  'vb/private/utils',
  'vb/private/log',
  'vb/private/constants',
  'urijs/URI',
  'vb/private/services/serviceUtils',
  'vb/private/services/swaggerUtils',
  'signals',
],
(Utils, Log, Constants, URI, ServiceUtils, SwaggerUtils, signals) => {
  const logger = Log.getLogger('/vb/private/services/servicesLoader');

  const EXTENSION_PREFIX = `${Constants.EXTENSION_PATH_NAME}/`;

  class ServicesLoader {
    /**
     * This method is used (instead of requireJS) for two cases:
     * 1) we need additional Request configuration, like headers, to make the request (not supportedby requireJS)
     * 2) using requireJS has failed, for some reason; this is only used as a fallback in this case.
     *
     * note: 'path' used to have to be prepended with Router.baseUrl by the caller;
     * this has changed for 19.1.3, we will prepend it here
     *
     * @param path
     * @param requestInit an object usable as the 'init' for a Request constructor
     * @returns {Promise} resolved with the the definition (as an object), or rejected
     */
    static loadWithFetchOrXHR(path, requestInit = {}) {
      return Utils.getResource('vb/private/stateManagement/router')
        .then((Router) => {
          // if we are using fetch() and the path is NOT absolute, we need to prepend the Router.baseUrl
          const isAbsolutePath = Utils.isAbsolutePath(path);
          const url = isAbsolutePath ? path : Router.baseUrl + path;

          // eslint-disable-next-line no-param-reassign
          requestInit.resolvedUrl = url;

          // only use XMLHttpRequest for local files on native mobile, for all other usecases stick to fetch()
          if (Utils.isMobile() && !isAbsolutePath) {
            return new Promise((resolve) => {
              const xhr = new XMLHttpRequest();
              // workaround for the persistence toolkit until they add support for the onload event (see JET-20785)
              // xhr.onload = function () {
              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  resolve(new Response(xhr.responseText, { status: xhr.status }));
                }
              };
              xhr.onerror = () => {
                console.error('Failed to fetch request: ', url);
              };
              const method = requestInit.method || 'GET';
              xhr.open(method, url, true);
              xhr.send(null);
            });
          }

          const init = Object.assign({}, requestInit, { credentials: 'same-origin' });
          const request = new Request(url, init);

          return fetch(request);
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw Error(`service fetch failed: ${path} : ${response.status} ${response.statusText}`);
        });
    }

    /**
     * changed for 18.4.3
     *
     * this method will use either fetch() or require().
     *
     * requireJS will be used if
     *  a) there is no additional request configuration (which currently means no 'headers') for the service def, AND
     *  b) the path to the service does not include an explicit protocol.
     *
     * Example of (a)...it is possible to get OpenAPI 3 for a Business Object using:
     *    Accept: application/vnd.oracle.openapi3+json
     *
     * So, if there are no headers or additional Request properties, and no protocol, use requireJS.
     * Otherwise, use fetch().
     *
     * if requireJS is used, the timeout is ignored, and the requireJS timeout applies (typically 7 seconds).
     *
     * fetch isn't cancelled when elapsed time reached 'timeout', we just don't wait anymore, and Promise rejects.
     *
     * (originally introduced with the change to use fetch() to get service definitions, because of timeouts)
     *
     * originally tried to put into Utils, but that caused a dependency loop:
     *    Utils -> Router -> Log -> Utils -> ...
     * @param url
     * @param requestInit
     * @param timeout defaults to Constants.defaultFetchTimeout (30 secs)
     * @returns {Promise<R>|Promise.<*>}
     */
    // NOTE: this is used by DT: DtRuntimeEnvironment. see BUFP-34538
    static loadWithTimeout(url, requestInit, timeout = Constants.Services.definitionTimeout) {
      // don't count vb-info-extension as a header
      const hasInfoExtensionHeader = requestInit.headers
        && requestInit.headers[Constants.Headers.VB_INFO_EXTENSION];

      const hasHeaders = requestInit.headers
        && Object.keys(requestInit.headers).length > (hasInfoExtensionHeader ? 1 : 0);

      // 'hasInitProps' means, there are additional properties for the Request, other than headers
      const hasInitProps = (Object.keys(requestInit).length > (requestInit.headers ? 1 : 0));

      function loadNotUsingRequireJS() {
        const timeoutError = new Error(`Service definition loading timed out: ${timeout}`);
        return Utils.promiseRaceWithTimeout(ServicesLoader.loadWithFetchOrXHR(url, requestInit), timeout, timeoutError);
      }

      if (!hasHeaders && !hasInitProps) {
        const urlInfo = URI.parse(url);
        if (!urlInfo.protocol) {
          return ServicesLoader.loadWithRequireJs(url, requestInit)
            .catch((err) => {
              // if requireJS fails with a timeout, use fetch()
              if (err && err.requireType === 'timeout') {
                logger.warn('Fetching service with requireJS timed out, trying fetch:', url, err);
                return loadNotUsingRequireJS();
              }
              throw err;
            });
        }
      }

      return loadNotUsingRequireJS();
    }

    /**
     * use require 'text' plugin (which we have configured to use XHR)
     * @param url
     * @param requestInit
     */
    static loadWithRequireJs(url, requestInit = {}) {
      // eslint-disable-next-line no-param-reassign
      requestInit.resolvedUrl = url;

      let loadPromise;
      // need to determine if its an extension
      if (url.startsWith(EXTENSION_PREFIX)) {
        loadPromise = Utils.getRuntimeEnvironment().then((re) => re.getExtensionTextResource(url));
      } else if (url.startsWith('text!')) {
        loadPromise = Utils.getResource(url);
      } else {
        loadPromise = Utils.getTextResource(url);
      }

      return loadPromise
        .then((def) => SwaggerUtils.parseServiceText(def));
    }

    /**
     * look up the url in the catalog, and return an object with any extension information found:
     * {
     *   url: the resolved url to use
     *   services: {
     *        - an object to use for initializing a Request object for fetch()ing the service def.
     *        - may be empty, the caller may decide to use something other than fetch().
     *        - note: this is for getting the service def, and NOT for calls made to the
     *      extensions: {
     *         headers: {
     *            - Currently only contains headers, and is merged with the 'existingHeaders' param.
     *            - endpoints in the def, a.k.a 'backends'.
     *         }
     *      }
     *   },
     *   backends: {
     *      extensions: {
     *         - any additional properties that should override or merge with any existing 'x-vb' extensions
     *         - for the 'backend' calls (and NOT for the call to get the the service def itself).
     *      }
     *   }
     * }
     * @param protocolRegistry
     * @param url
     * @param serviceName typically the service name; added to the vb-info-extension, and used by the plugins
     *         to construct a proxy url, if needed.
     * @param namespace {string}
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @param {object} [serviceDefHeaders] - any headers associated with the service declaration
     *                                       before catalog resolution, if any. unused for endpoint resolution.
     * @returns {Promise} { url, services: { extensions }, backends: { extensions } }
     */
    static getCatalogExtensions(protocolRegistry, url, serviceName, namespace, serverVariables, serviceDefHeaders) {
      return protocolRegistry.getResolvedInfoOrDefault(url, namespace, serverVariables)
        .then((resolved) => ServiceUtils
          .transformResolvedCatalogObject(resolved, serviceName, url, serviceDefHeaders || {}));
    }

    /**
     * @param serviceDefinition
     * @param serverVariables
     * @returns {*}
     */
    static notify(serviceDefinition, serverVariables) {
      const id = serviceDefinition.name;
      const nameForProxy = serviceDefinition.nameForProxy || id;
      const servers = serviceDefinition.getServers();

      const ext = serviceDefinition.extensions;
      const catalogInfo = serviceDefinition.catalogInfo;
      // include the 'metadata', for when the service is really coming from the catalog indirection.
      const metadata = (catalogInfo && catalogInfo.services && catalogInfo.services.metadata) || {};

      const urlInfos = servers.map((server) => Object.assign(
        {
          url: server.getUrl(serverVariables),
          id,
          nameForProxy,
          metadata,
        },
        ext,
      ));

      return ServicesLoader.opened.dispatch(id, urlInfos, serviceDefinition.namespace);
    }
  }

  ServicesLoader.opened = new signals.Signal();

  return ServicesLoader;
});


'use strict';

define('vb/private/stateManagement/switcherPageMixin',[
  'ojs/ojrouter',
  'vb/private/stateManagement/page',
  'vb/private/stateManagement/router',
  'vb/private/history',
  'vb/private/constants',
], (ojRouter, Page, Router, History, Constants) => {
  /**
   * Mixin to be used with pages running in a switcher
   * It modifies the original behavior of the page to run in a oj-vb-switcher component
   */
  const SwitcherPageMixin = (superclass) => class extends superclass {
    constructor(id, parent, path, className) {
      super(id, parent, path, className);

      this.switcher = parent.switcher;
      this.switcherElementId = parent.switcherElementId;

      // Use to store the DOM between switching
      this._viewCache = null;
    }

    /**
     * Check if this page can be embbeded in the oj-vb-switcher component
     * @throws an error id the page is not embeddable
     */
    checkEmbeddable() {
      const switcherElementData = this.switcher.getElementData(this.switcherElementId);

      if (!switcherElementData.started) {
        const { navigation } = this.definition;
        if (!navigation || navigation.embeddable !== 'enabled') {
          throw new Error(`Page ${this.getNavPath()} is not allowed to be embedded.`);
        }
      }
    }

    /**
     * Navigation initiated from inside a switcher should skip URL change and onlu push state on
     * browser history.
     * Override the Container.navigateOperation
     */
    navigateOperation(options, navContext) {
      return Promise.resolve().then(() => {
        switch (options.operation) {
          case Constants.NavigateOperation.FLOW:
            if (options.target === 'parent') {
              this.log.error('Navigate the parent flow not supported inside a switcher.');
              return { navigated: false };
            }
            break;

          case Constants.NavigateOperation.APP_UI:
            this.log.error('Navigate to application not supported inside a switcher.');
            return { navigated: false };

          default:
            break;
        }

        // eslint-disable-next-line no-param-reassign
        options.history = Constants.HistoryMode.SKIP;

        return super.navigateOperation(options, navContext)
          .then((result) => {
            if (result && result.navigated === true) {
              History.pushState();
              const flowElt = this.switcher.getCurrentElementData();
              History.setVariable('internal', 'flowElt', flowElt);
              History.sync();
            }

            return result;
          });
      });
    }

    /**
     * Page in a switcher don't exit unless the element is removed
     *
     * @return {boolean} True if able to exit, False otherwise.
     */
    canExit() {
      const switcherElementData = this.switcher.getElementData(this.switcherElementId);
      if (switcherElementData.toDelete) {
        return super.canExit();
      }

      return Promise.resolve(true);
    }

    /**
     * Page in a switcher don't exit unless the element is removed
     */
    exit() {
      const switcherElementData = this.switcher.getElementData(this.switcherElementId);
      if (switcherElementData.toDelete) {
        return super.exit();
      }

      return Promise.resolve();
    }

    /**
     * On enter the content of the switcher is changed
     */
    enter() {
      // Only does it when the container is initialized
      if (this.scope) {
        // The parent moduleConfig is the ojModule that contain the page info
        const parentModuleConfig = this.getParentFlow().getParentModuleConfig();
        if (parentModuleConfig && parentModuleConfig().pagePath !== this.fullPath) {
          parentModuleConfig(this.createModuleConfig());
        }

        return Promise.resolve();
      }

      return super.enter();
    }

    /**
     * Probagate the vbAfterNavigate eevent ot the switcher to keep track of the navigation
     * in the switcher.
     */
    invokeEventWithBubbling(eventName, eventPayload) {
      if (eventName === Constants.AFTER_NAVIGATE_EVENT) {
        this.switcher.updateCurrentPagePath(eventPayload.currentPage);
      }

      return super.invokeEventWithBubbling(eventName, eventPayload);
    }

    createModuleConfig() {
      return {
        viewModel: this.getViewModel(),
        view: this._viewCache || this.getView(),
        // This is used to stop ojModule to cleanup DOM
        cleanupMode: 'none',

        pagePath: this.fullPath,
      };
    }

    /**
     * Only disconnect when the element of the switcher is removed.
     */
    disconnected(event) {
      const switcherElementData = this.switcher.getElementData(this.switcherElementId);
      if (switcherElementData.toDelete) {
        this._viewCache = null;
        super.disconnected();
        this.switcher.delete(this.switcherElementId);
      } else {
        this._viewCache = event;
      }
    }
  };

  return SwitcherPageMixin;
});


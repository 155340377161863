'use strict';

define('vb/private/stateManagement/context/pageContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/pageBaseContext',
  'vb/helpers/componentFinder'],
(Constants, ContainerContext, PageBaseContext, ComponentFinder) => {
  /** @type Object */
  const symbol = Symbol('page-accessor');
  /**
   * set of properties to expose in $page
   */
  class PageContext extends ContainerContext {
    /**
     * @param page
     */
    constructor(page) {
      super(page);
      const accessors = {
        [Constants.COMPONENTS_CONTEXT]: () => ComponentFinder,
      };

      Object.keys(accessors).forEach((accessorName) => {
        Object.defineProperty(this, accessorName, {
          enumerable: true,
          configurable: true,
          get: accessors[accessorName],
        });
      });
      Object.defineProperty(this, symbol,
        {
          value: {
            /**
             * Shortcut to retrieve the builtins variable map
             */
            get builtins() {
              return page.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN];
            },
          },
        });
    }

    static get BaseContextType() {
      return PageBaseContext;
    }

    /*
     * $page.currentFlow
     */
    get [Constants.CURRENT_FLOW_VARIABLE]() {
      return this[symbol].builtins[Constants.CURRENT_FLOW_VARIABLE];
    }

    /*
     * $page.info
     */
    get [Constants.INFO_CONTEXT]() {
      return this[symbol].builtins[Constants.INFO_CONTEXT];
    }

    /**
     * see ContainerContext
     *
     * @param page
     * @returns {{$application: *, $variables, $metadata}}
     */
    static getAvailableContexts(page) {
      const availableContexts = super.getAvailableContexts(page);

      Object.defineProperties(availableContexts, {
        $flow: {
          enumerable: true,
          configurable: true,
          get: () => page.parent.expressionContext,
        },
        // everything from here down used to only be created when the Page's Scope was created
        // now, we create the object once, up front, and rely on getters to allow deferred assignment
        // of the expressionContext.  But, because Expression reads all the values, regardless of what is used
        // in the current expression, the properties may be read before expressionContex is created,
        // so we guard against expressionContex being null.  Should not be a problem,
        // since the null pre-Scope values cannot be meaningfully referenced anyway.
        $page: {
          enumerable: true,
          configurable: true,
          get: () => page.expressionContext,
        },
      });

      return availableContexts;
    }
  }

  return PageContext;
});


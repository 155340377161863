'use strict';

define('vb/private/vx/v1/remoteExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'urijs/URI',
], (BaseExtensionAdapter, URI) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends BaseExtensionAdapter {
    constructor(registryUrl) {
      super(BaseExtensionAdapter.fixRegistryUrl(registryUrl));
    }

    /**
     * Build the request and call the base to make the fetch call
     * @param {String} applicationId
     */
    initiateLoadManifest(applicationId) {
      if (!applicationId) {
        throw new Error('Cannot load extension, application id is not defined');
      }

      const version = this.constructor.getExtensionVersion();
      this.log.info('Loading extensions from', this.registryUrl, 'for application', applicationId,
        'using digest version "', version, '" and extension manager v1.');

      const request = new Request(this.buildUrl(applicationId, version));

      this.initiateFetchManifest(request);
    }

    /**
     * Return a promise to load the manifest given the extensionId.
     * @param  {String} applicationId the id of the application for which we load an extension
     * @return {Promise<Object>} a promise that resolves to the manifest
     */
    loadManifest(applicationId) {
      return this.fetchManifest()
        .then((results) => {
          const manifest = results || {};
          manifest.extensions = manifest.extensions || [];

          this.log.info('Found', manifest.extensions.length, 'extension(s) for application',
            applicationId);

          return manifest;
        });
    }

    /**
     * Build the extension URL based on the baseUrl and the extension id
     * A typical extension URL with a sandbox defined looks like:
     * https://<fa-server>/fscmUI/vxmanifest/v1/applications/<app name>/ui-manifest?preferExtensionVersion=$<sandboxId>
     * @param  {String} applicationId the id of the application for which we load an extension
     * @param  {String} version       the preferred extension version
     * @return {String}               the extension URL
     */
    buildUrl(applicationId, version) {
      const uri = new URI(`${this.registryUrl}vxmanifest/v1/applications`);

      // Append the applicationId and method
      uri.segmentCoded(applicationId);
      uri.segment('ui-manifest');

      if (version) {
        // Use this syntax because we don't want to encode the $ in sandbox
        uri.search(`preferExtensionVersion=${version}`);
      }

      return uri.href();
    }
  }

  return RemoteExtensionAdapter;
});


'use strict';

define('vb/private/vx/v2/remoteExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'urijs/URI',
  'vbsw/private/constants',
], (BaseExtensionAdapter, URI, SwConstants) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends BaseExtensionAdapter {
    constructor(registryUrl) {
      super(BaseExtensionAdapter.fixRegistryUrl(registryUrl));
    }

    initiateLoadManifest() {
      const version = this.constructor.getExtensionVersion();
      this.log.info('Loading extensions from', this.registryUrl, 'using digest version "', version,
        '" and extension manager v2.');

      const request = new Request(this.buildUrl(version));
      // use offline toolkit to handle caching
      request.headers.set(SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE, 'true');

      this.initiateFetchManifest(request);
    }

    /**
     * Return a promise to load the manifest for the unified application
     * @return {Promise<Object>}  a promise that resolves to the manifest
     */
    loadManifest() {
      return this.fetchManifest()
        .then((results) => {
          const digest = (results && results.digest) || {};
          // Extension Manager v2 returns digest.runtime[], so convert to v1 manifest.extensions[]
          const extensions = digest.runtime || [];
          const requirejsInfo = digest['requirejs-info'] || [];
          const appUiInfo = digest['app-ui-info'] || [];

          const manifest = { extensions, appUiInfo, requirejsInfo };

          this.log.info('Found', manifest.extensions.length, 'extension(s).');

          return manifest;
        });
    }

    static getExtensionVersion() {
      const version = BaseExtensionAdapter.getExtensionVersion();

      if (!version) {
        // For v2 adds the digest version if defined. Default to 'latest'
        const digestVersion = window.vbInitConfig && window.vbInitConfig.REGISTRY_DIGEST_VERSION;
        return digestVersion ? encodeURIComponent(digestVersion) : 'latest';
      }
      return version;
    }

    /**
     * Build the extension URL based on the baseUrl and the extension id
     * A typical extension URL with a sandbox defined looks like:
     * https://<fa-server>/fscmUI/vxmanifest/v2/digest/[latest|$<sandboxId>]?type=topology
     * @param  {String} version     the preferred extension version
     * @return {String}             the extension URL
     */
    buildUrl(version) {
      const uri = new URI(`${this.registryUrl}vxmanifest/v2/digests/`);

      // The version string has already been encoded, so don't encoded it again in
      // order to preserve the $ character
      uri.segment(version);
      // Ask for runtime, app-ui-info and requirejs-info digests
      uri.search('type=runtime,requirejs-info,app-ui-info');

      return uri.href();
    }
  }

  return RemoteExtensionAdapter;
});


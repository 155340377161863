'use strict';

define('vb/private/stateManagement/redux/reduxRouter',[
  'ojs/ojrouter', 'signals',
], (ojRouter, signals) => {
  const ROUTER_ACTION_TYPE = '@@oj/TRANSITIONED';
  const ROUTER_PROPERTY_NAME = 'routerId';
  const ROUTER_ID = 'router';

  class ReduxRouter {
    constructor(router) {
      this.router = router;

      // Higher order reducer to incorporate the router state
      this.reducer = (initialState, action) => {
        initialState = initialState || {};

        if (action.type === ROUTER_ACTION_TYPE) {
          const state = {};
          state[ROUTER_PROPERTY_NAME] = router.stateId();
          return Object.assign({}, initialState, state);
        }

        return initialState;
      };
    }

    syncRouterWithStore(store) {
      this.store = store;

      /**
       * A signal dispatched after navigation occured.
       * @type {signals}
       */
      this.navigated = new signals.Signal();

      /* Disable Time Travel support for the router until there is a better way
         then checking the stateId to detect a state change. This doesn't work
         when a new scope is created (by example in an action chain) during
         navigation.

      // Subscribe to state changes in order to navigate during time travel
      this.stateSubscription = this.store.subscribe(() => {
        let routerId = this.getRouterId();

        // Only navigates if state is different
        // This is for the case where the state is changed because of time travel.
        if (routerId !== this.router.stateId()) {
          this.router.navigateToPage(routerId);
        }
      }); */

      // Register a listener on signal transitionedToState
      this.signal = ojRouter.transitionedToState.add((result) => {
        if (result.hasChanged) {
          this.store.dispatch({ type: ROUTER_ACTION_TYPE });
        }

        this.navigated.dispatch(result.hasChanged);
      });
    }

    getState() {
      if (this.store) {
        return this.store.getState()[ROUTER_ID];
      }
    }

    /**
     * Return the current router id
     * @return {string} the current state id of the router
     */
    getRouterId() {
      let state = this.getState();
      if (state) {
        return state[ROUTER_PROPERTY_NAME];
      }
    }

    static get KEY() {
      return ROUTER_ID;
    }

    dispose() {
      if (this.signal) {
        this.signal.detach();
      }

      if (this.stateSubscription) {
        this.stateSubscription();
      }

      if (this.navigated) {
        this.navigated.removeAll();
      }
    }
  }

  return ReduxRouter;
});


/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/checkCancelEventBehavior',[
  'vb/private/events/eventBehavior',
  'vb/private/constants',
  'vb/private/log',
], (EventBehavior, Constants, Log) => {
  const logger = Log.getLogger('/vb/private/events/checkCanceleventBehavior');
  /**
   * CheckCancelEventBehavior, behavior = "checkForCancel"
   */
  class CheckCancelEventBehavior extends EventBehavior {
    /**
     * call the (curried) invokeEvent functions serially, but stop when one returns a {"success", true} outcome
     * @param functionWrappers
     * @private
     * @override
     */
    executeInternal(functionWrappers) {
      const results = [];
      let cancelled = false;
      return functionWrappers
        .reduce((p, wrapper) => p
          .then((result) => {
            // if the previous one did not return a cancel result, keep going
            if (!cancelled) {
              if (!CheckCancelEventBehavior.isCancelResult(result)) {
                return Promise.resolve(wrapper.fnc(this))
                  .then((r) => {
                    results.push(r);
                    return r;
                  });
              }
              logger.info(`Event propagation cancelled for event ${this.name}`);
              cancelled = true;
            }
            return null;
          }),
        Promise.resolve(Constants.NO_EVENT_LISTENER_RESPONSE)); // @todo: dummy result for first invocation
    }

    /**
     * @param result
     * @returns {*} truthy value if the result is "success" with a payload of { stopPropagation: true }
     * @private
     */
    static isCancelResult(result) {
      return (Array.isArray(result)
        && result.some((wrapper) => wrapper
          && wrapper.result.name === 'success'
          && wrapper.result.result && wrapper.result.result.stopPropagation === true));
    }


    /**
     * opposite of 'default' behavior; extensions get the event first, then base
     * @param container
     * @param eventModel
     * @param eventWrapper
     * @param expressionContexts
     * @returns {Array<{ container: Container, fnc: function }>}
     * @override
     */
    curryListeners(container, eventModel, eventWrapper, expressionContexts) {
      const functionWrappers = [];
      if (eventModel.isInterface) {
        const fncWrappers = this
          .recurseExtensionsAndCreateFunctions(container, eventWrapper, eventModel, expressionContexts);
        Array.prototype.push.apply(functionWrappers, fncWrappers);
      }

      const fnc = container.invokeEvent.bind(container, eventWrapper.name, eventWrapper.coercedPayload);
      functionWrappers.push({
        container,
        fnc,
      });

      return functionWrappers;
    }
  }

  return CheckCancelEventBehavior;
});


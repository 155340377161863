'use strict';

define('vb/private/stateManagement/layout',[
  'vb/private/stateManagement/container',
  'vb/private/stateManagement/context/layoutContext',
  'vb/private/stateManagement/layoutExtension',
  'vb/private/stateManagement/layoutMixin',
  'vb/private/utils',
  'vb/private/constants',
  'vb/errors/httpError',
  'vb/helpers/mixin',
  'vb/private/stateManagement/fragmentHolderMixin',
  'vb/private/stateManagement/fragment',
  'vb/private/stateManagement/packageFragment',
  'vb/private/stateManagement/baseModuleViewModel',
], (Container, LayoutContext, LayoutExtension, LayoutMixin, Utils, Constants, HttpError, Mixin, FragmentHolderMixin,
  Fragment, PackageFragment, BaseModuleViewModel) => {
  /**
   * Layout class: A VB model for dynamic layout
   *
   */
  class Layout extends Mixin(Container).with(LayoutMixin, FragmentHolderMixin) {
    /**
     * Layout constructor
     *
     * @param {String} id        a string id to identify the layout, for the
     * MetadataProviderHelper case, this will be the endpoint id
     * @param {Page} page        the page that contain this layout
     * @param {Extension} extension the extension from which the layout should be loaded
     * @param {String} path       the absolute path to the layout, e.g., vb/extA/dynamicLayouts/self/employee
     * @param {String} className the class name
     */
    constructor(id, page, extension, path, className = 'Layout') {
      // There might be multiple instance of this layout in a page,
      // so generate a unique id.
      super(id, page, className);

      if (page) { // some test don't include the container
        page.registerLayout(this);
      }

      // Override value defined in Container
      this._extension = extension || this.extension;

      Object.defineProperties(this, {
        path: {
          // It is possible the layout is created with a path already been set. In that
          // case, make sure it's not a dup of baseUrl
          value: Layout.calculatePath(path, this.extension.baseUrl),
          enumerable: true,
        },
      });
    }

    /**
     * Redefine the value of the name property and this time
     * it's read-only and cannot be redefined again (configurable is false)
     * The name is always layout, layout.json, layout-x.json
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
      return 'layout';
    }

    /**
     * title and description are not part of layout model. id can be used when layouts are stamped
     * @return {{id: ({enumerable: boolean, value: *}|{enumerable: boolean, value: *})}}
     */
    defineInfoBuiltinVariable() {
      return {
        id: this.id,
      };
    }

    /**
     * Called to determine if an event behavior is supported by container. Most are except for dynamicComponent
     * behavior that is only supported on select container. See Layout and Fragment.
     * @param eventBehavior
     * @return {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    allowsDynamicComponentEventBehavior(eventBehavior) {
      return eventBehavior === Constants.EventBehaviors.DYNAMIC_COMP;
    }

    /**
     * Called to determine if propagation behavior is supported by layout.
     * @param type of propagation behavior being checked for. See Constants.EventPropagationBehaviors enum.
     * @param eventDef event definition
     * @return {boolean} true if behavior matches the type and the container allows the requested type
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    allowsEventPropagation(type, eventDef) {
      switch (type) {
        case Constants.EventPropagationBehaviors.CONTAINER:
          // event can bubble up to container only if the type requested matches the behavior and event is declared
          return eventDef.propagationBehavior === Constants.EventPropagationBehaviors.CONTAINER && eventDef.isDeclared;

        case Constants.EventPropagationBehaviors.SELF:
        default:
          return false; // propagating event outside of self is disallowed;
      }
    }

    static get extensionClass() {
      return LayoutExtension;
    }

    /**
     * the static getter cannot evaluate this.extensionId from the container; so this method is needed
     * @return {Promise} resolves with the Fragment class/module to use
     */
    getFragmentClass() {
      return Promise.resolve().then(() => {
        if (this.extensionId !== Constants.ExtensionNamespaces.BASE) {
          // Layouts can be defined in base/regular apps, as well as extensions. When extensionId is something other
          // than 'base' use PackageFragment until this is removed via VBS-15065
          return PackageFragment;
        }
        return Fragment;
      });
    }

    /**
     * Use to calculate the path of the layout.
     * It is possible the layout is created with a path already been set. In that
     * case, make sure it's not a dup of baseUrl
     * @param  {String} path
     * @param  {String} baseUrl
     * @return {String}
     */
    static calculatePath(path, baseUrl) {
      if (path && baseUrl && path.startsWith(baseUrl)) {
        return path.substring(baseUrl.length);
      }

      return path;
    }

    /**
     * Return true if error is a FileNotFound error.
     *
     * @param error
     * @returns {Boolean|boolean|*}
     */
    static isFileNotFoundError(error) {
      return error instanceof HttpError && error.isFileNotFound();
    }

    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // App UI are using extension with a manifest containing a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.extension.fileExists(`${this.resourceLoc}${this.name}.json`);
        const resource = `${resourceLocator}.json`;
        return this.application.runtimeEnvironment.getTextResource(resource)
          .then((jsonContent) => Utils.parseJsonResource(jsonContent, resource));
      })
        // Return an empty layout when the base layout doesn't exist to support the use case
        // where an extension needs to extend a layout that doesn't exist in the base extension.
        .catch((error) => {
          if (Layout.isFileNotFoundError(error)) {
            return { layouts: {} };
          }
          throw error;
        });
    }

    dispose() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });
      super.dispose();
    }

    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.extension.fileExists(`${this.resourceLoc}${this.name}.js`);
        return super.functionsLoader(resourceLocator);
      })
        // Return an empty JS module when the base module doesn't exist to support the use case
        // where an extension needs to extend a layout that doesn't exist in the base extension.
        .catch((error) => {
          if (Layout.isFileNotFoundError(error)) {
            return {};
          }
          throw error;
        });
    }

    templateLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.extension.fileExists(`${this.resourceLoc}${this.name}.html`);
        return super.templateLoader(`${resourceLocator}.html`);
      })
        // Return an empty template when the base template doesn't exist to support the use case
        // where an extension needs to extend a layout that doesn't exist in the base extension.
        .catch((error) => {
          if (Layout.isFileNotFoundError(error)) {
            return '';
          }
          throw error;
        });
    }

    /**
     * @return {Promise}
     */
    loadTemplate() {
      return super.loadTemplate()
        .catch((e) => {
          // swallow error, html isn't required for Layout
          // swallow errors for missing files, log the rest
          if (!e.requireType || e.requireType !== 'scripterror') {
            this.log.error('Error loading module', this.getResourcePath(), e);
          }
          return Promise.resolve();
        });
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getModuleResource';
    }

    /**
     * The name of the runtime environment function to be used to load the html
     *
     * @type {String} the template loader function name
     */
    static get templateLoaderName() {
      return 'getTextResource';
    }

    /**
     * returns the LayoutContext constructor used to create the '$' expression context
     * @type {LayoutContext}
     */
    static get ContextType() {
      return LayoutContext;
    }

    initDefault(definition) {
      const def = definition;

      // Temporarily provide backward compatibility for the dynamic component metadata. This is
      // for the case where layoutTypes is not yet used by the dynamic component. In that case we
      // can only assume types is for the dynamic component, not the VB model.
      if (def.types && !def.layoutTypes) {
        delete def.types;
      }

      return super.initDefault(def);
    }

    /**
     * Returns a promise that resolves when all resources associated with the layouit container are loaded
     */
    load() {
      this.loadPromise = this.loadPromise || this.loadMetadata()
        .then(() => Promise.all([this.loadExtensions(), this.loadImports(), this.loadTranslationBundles()]))
        .then(() => {
          this.combineExtensions();
          // Setup the component event listeners
          this.initializeEvents();

          // initialize action chains
          this.initializeActionChains();

          return this.initAllVariableNamespace();
        });

      return this.loadPromise;
    }

    /**
     * Returns the model object for the layout container. The model contains all the accessible $ properties
     * of the layout container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the layout.
     * The model is bound to the layout view by the JET dynamic component.
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     *   $layout: {}
     * }
     *
     * @return {Promise<BaseModuleViewModel>} a promise that resolve with the model object.
     */
    getViewModel() {
      return Promise.all([
        this.load(), this.loadFunctionModule(), this.loadTemplate(),
      ])
        .then(() => new BaseModuleViewModel(this));
    }

    /**
     * Used by event processing. For Layout containers, we start (and end) with the Layout itself,
     * unless it is a "dynamicComponent" event, which uses a completely different propagation implementation.
     * (For 'dynamicComponent' behavior, FireCustomEventAction delegates to the JET component.)
     * @see FireCustomEventAction
     *
     * @returns {Layout}
     */
    getLeafContainer() {
      return this;
    }

    getScopeResolverMap() {
      const map = {
        [Constants.LAYOUT_PREFIX]: this,
      };

      if (this.extensionId !== Constants.ExtensionNamespaces.BASE) {
        // for now expose global scope to layouts in extensions so fragments referenced from layouts can have access
        // to global scope.
        Object.assign(map, {
          [Constants.GLOBAL_PREFIX]: this.application,
        });
      } else {
        Object.assign(map, {
          [Constants.APPLICATION_PREFIX]: this.application,
        });
      }

      return map;
    }

    getCallingContext() {
      const parentCallingContext = this.parent && this.parent.getCallingContext();
      if (parentCallingContext) {
        return {
          sourceExtension: parentCallingContext.sourceExtension,
        };
      }

      return super.getCallingContext();
    }

    /**
     * Add the extension id as part of the name to make it unique between
     * multiple extensions.
     * @return {String} a new scope name
     */
    getNewScopeName() {
      return `${this.className}/${this.extensionId}/${this.fullPath}`;
    }
  }

  return Layout;
});


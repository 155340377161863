'use strict';

define('vb/private/translations/translationContext',[
  'ojs/ojtranslation',
  'vb/private/constants',
], (
  ojTranslation,
  Constants,
) => {
  /**
   *
   */

  class TranslationContext {
    constructor() {
      throw new Error('TranslationContext is a set of static methods, do not call constructor');
    }

    /**
     * create an object with just properties we want to expose to the expression context
     * Only the V1 translations bundles are included in this context, plus the format() function.
     * @param bundlesModel {BundlesModel}
     * @returns {*}
     */
    static createV1Context(bundlesModel) {
      // if no bundles yet, just return the utility method(s), and don't look up the key
      // just in case this is called before the bundles are loaded, which shouldn't happen
      if (!bundlesModel) {
        return {
          format: (_, str, ...params) => ojTranslation.applyParameters(str, ...params),
        };
      }

      const utilities = {
        format: (bundleName, key, ...params) => {
          // if no bundleName is passed, assume they are referencing a bundle named 'format'
          if (typeof bundleName === 'undefined') {
            return bundlesModel.getStringMap().format;
          }

          // delegate formatting to the actual instance (of BundleDefinition).
          const bundleDef = bundlesModel.getBundleDefinition(bundleName);
          return bundleDef.format(key, ...params);
        },
      };

      return Object.assign({}, bundlesModel.getStringMap(), utilities);
    }

    /**
     * create an object with just properties we want to expose to the expression context
     * Both V1 and V2 translations bundles are included in this context.
     * If there are V1 translations bundles included in this context, the format() function is added.
     * @param bundlesModel {BundlesModel}
     * @returns {*}
     */
    static createContext(bundlesModel) {
      // if no bundles yet, just return the utility method(s), and don't look up the key
      // just in case this is called before the bundles are loaded, which shouldn't happen
      if (!bundlesModel) {
        return {
          format: (_, str, ...params) => ojTranslation.applyParameters(str, ...params),
        };
      }

      const v1StringMap = bundlesModel.getStringMap();
      const v2StringMap = bundlesModel.getV2StringMap();

      // If there are V1 Strings (or no V2 Strings), we need to expose the format() utility
      // (If the BundleModel is completely empty, it will still have a format() utility)
      if (Object.keys(v1StringMap).length || !Object.keys(v2StringMap).length) {
        const utilities = {
          format: (bundleName, key, ...params) => {
            // if no bundleName is passed, assume they are referencing a bundle named 'format'
            if (typeof bundleName === 'undefined') {
              return v1StringMap.format;
            }

            // delegate formatting to the actual instance (of BundleDefinition).
            const bundleDef = bundlesModel.getBundleDefinition(bundleName);
            return bundleDef.format(key, ...params);
          },
        };

        return Object.assign({}, v1StringMap, v2StringMap, utilities);
      }

      // No V1 Strings, the context is just the V2 String map
      return v2StringMap;
    }

    /**
     * Add $extension.<extId>.translations.<bundleName> for all extension translation bundles referenced by the
     * bundlesModel (i.e. translation bundles in other extensions)
     * @param bundlesModel {BundlesModel}
     * @param extensionsContext {Object} extensions context to add the <extId>.translations contexts
     */
    static addExtensionsContexts(bundlesModel, extensionsContext) {
      const extensionsBundles = Object.entries(bundlesModel.extensionsBundleMap);
      if (extensionsBundles.length) {
        extensionsBundles.forEach(([extId, bundle]) => {
          if (!extensionsContext[extId]) {
            // If there isn't already an extension entry for extId, create one with the "translations" property
            // set to the extension bundle.
            Object.defineProperty(extensionsContext, extId, {
              enumerable: true,
              configurable: true,
              value: {
                [Constants.TRANSLATIONS_CONTEXT]: bundle,
              },
            });
          } else {
            // Just add the "translations" property to the existing extId extension
            Object.defineProperty(extensionsContext[extId], Constants.TRANSLATIONS_CONTEXT, {
              enumerable: true,
              configurable: true,
              value: bundle,
            });
          }
        });
      }
    }
  }

  // all static
  return TranslationContext;
});


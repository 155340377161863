'use strict';

define('vb/private/stateManagement/switcherFlowMixin',[
  'knockout',
  'vb/helpers/mixin',
  'vb/private/stateManagement/switcherPageMixin',
], (ko, Mixin, SwitcherPageMixin) => {
  /**
   * Mixin to be used with flows running in a switcher
   * It modifies the original behavior of the flow to run in a oj-vb-switcher component
   */
  const SwitcherFlowMixin = (superclass) => class extends superclass {
    constructor(id, switcher, parent, path, className) {
      const parts = id.split(':');
      const flowId = parts[1];
      super(id, parent, parent.parent.calculateRequirePath(flowId), className);

      this._name = flowId;

      // Keep track of the element id
      this._switcherElementId = parts[0];

      // Keep a reference to the switcher
      this._switcher = switcher;
    }

    /**
     * Readonly property initialized the constructor, there is no setter.
     *
     * @type {Switcher}
     */
    get switcher() {
      return this._switcher;
    }

    get switcherElementId() {
      return this._switcherElementId;
    }

    /**
     * Override the name with the flowId, otherwise it contains the ':'
     */
    get name() {
      return this._name;
    }

    /**
     * When a page is created in a switcher, mix in the switcher functionality
     */
    createPage(pageInfo, path) {
      return new (Mixin(this.constructor.PageClass).with(SwitcherPageMixin))(pageInfo.id, this, path);
    }

    /**
     * The module config is handled by the switcher
     */
    getParentModuleConfig() {
      return this.switcher.moduleConfig;
    }

    enter() {
      if (this.scope) {
        return Promise.resolve();
      }

      return super.enter();
    }

    /**
     * Flow in a switcher don't exit unless the element is removed
     */
    exit() {
      const switcherElementData = this.switcher.getElementData(this.switcherElementId);
      if (switcherElementData.toDelete) {
        return super.exit();
      }

      return Promise.resolve();
    }
  };

  return SwitcherFlowMixin;
});


'use strict';

define('vb/private/services/definition/pluginServiceDefinition',[
  'vb/private/services/endpoint',
  'vb/private/services/serviceUtils',
  'vb/private/services/definition/definitionObject',
  'vb/private/services/readers/openApi3Object',
], (
  Endpoint,
  ServiceUtils,
  DefinitionObject,
  OpenApi3Object,
) => {
  /**
   * @typedef {Object} Server
   * @property {(function(*): string)} getUrl
   * @property {Object} [variables]
   */

  /**
   * @typedef {Object} Service
   * @property {Object} info
   * @property {Map<string, *>} paths
   * @property {Server[]} servers
   */

  /**
   * @typedef {Object} Backend
   * @property {boolean} [extensionAccess]
   * @property {Server[]} servers
   */

  /**
   * @typedef Catalog
   * @property {Map<string, Backend>} backends
   * @property {Map<string, Service>} services
   */

  /**
   * @typedef {Object} CatalogInfo
   * @property {string} url
   * @property {string} namespace
   * @property {Object[]} chain
   * @property {Object} backends
   * @property {Object} services.extensions
   * @property {Object} services.extensions.headers
   * @property {string} services.extensions.serviceType
   * @property {Object} services
   * @property {Object} services.extensions
   * @property {Object} services.extensions.headers
   * @property {Object} services.metadata
   * @property {Object} metadata
   * @property {Object} mergedExtensions
   * @property {string} mergedExtensions.serviceName
   * @property {string} mergedExtensions.serviceType
   * @property {Object} mergedExtensions.headers
   * @property {string} mergedExtensions.resolvedProxyUrl
   * @property {string} mergedExtensions.resolvedTokenRelayUrl
   * @property {boolean} mergedExtensions.trapEnabled
   */

  /**
   * ServiceDefinition backed by the programmatic endpoint plugin.
   */
  class PluginServiceDefinition extends DefinitionObject {
    /**
     *
     * @param {EndpointReference} endpointReference
     * @param plugin
     * @param {string} serviceType
     * @param {Catalog} catalog
     * @param {CatalogInfo} catalogInfo catalog.json information, if any
     * @param {ProtocolRegistry} protocolRegistry utility that handles vb-specific protocols
     */
    constructor(
      endpointReference,
      plugin,
      serviceType,
      requestInit,
      catalog,
      catalogInfo,
      protocolRegistry,
    ) {
      // this will be "businessObjects#crm" for  "@base:businessObjects#crm/orders_getall" endpoint
      const name = endpointReference.serviceName;
      super(
        name,
        {}, // extensions - DefinitionObject will get them from catalogInfo.backends.extensions
        null, // parent
        endpointReference.namespace,
        '', // relativePath
        catalogInfo,
        true, // isUnrestrictedRelative
      );

      this._endpointReference = endpointReference;

      this._plugin = plugin;
      this._serviceType = serviceType;

      this._requestInit = requestInit;
      this._catalog = catalog;
      this._protocolRegistry = protocolRegistry;

      // setup properties (almost) the same way the ServiceDefinition does it

      this._server = {
        // the URL is already resolved
        getUrl: () => this.catalogInfo.url,
      };

      this._nameForProxy = ServiceUtils.getNameForProxy(name, /* path */ '', catalogInfo.chain);

      // we need the augmented info for the 'legacy' syntax, also, for http/https promotion via proxy
      this.extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo.chain, this.extensions);

      this._endpoints = {};
    }

    get server() {
      return this._server; // called by new EndpointMetadata
    }

    get requestInit() {
      return this._requestInit;
    }

    get nameForProxy() {
      return this._nameForProxy; // called by Endpoint.getAllHeaders
    }

    /**
     * Programmatic endpoint is extension accessible if the backend it references is extension accesible.
     *
     * @return {boolean} true if this backend definition is visible outside the container that defines it
     *                   (i.e., if an extension can use it).
     */
    isExtensionAccessible() {
      const backend = this._catalogInfo.chain[0];
      return (backend && backend.extensionAccess) === true;
    }

    /**
     *
     * @param {EndpointReference} endpointRef Endpoint reference
     * @returns {Endpoint}
     */
    findEndpoint(endpointRef, serverVariables) {
      const key = this.getEndpointReferenceHash(endpointRef);

      if (this._endpoints[key] === undefined) {
        this._endpoints[key] = Promise.resolve()
          .then(() => this._getEndpointFromPlugin(endpointRef, serverVariables))
          .then(({
            operation, path, method, extensions, parameters,
          } = {}) => {
            if (operation) { // operationObject definition
              // mock OpenApi object in order to create OperationObject
              const openApi = {
                getExtensions: () => (extensions || {}),
              };
              const pathObject = {
                parameters: parameters || [],
              };
              const operationObj = new OpenApi3Object.OpenApi3OperationObject(method, pathObject, operation, openApi);
              const options = {
                name: endpointRef.operationId,
                service: this,
                protocolRegistry: this._protocolRegistry,
                pathKey: path,
                operationKey: method,
                operationObject: operationObj,
                isUnrestrictedRelative: this.isUnrestrictedRelative, // ?? does this apply in this context?
              };
              // create new Ednpoint from def
              return new Endpoint(options);
            }
            return null;
          });
      }
      return this._endpoints[key];
    }

    // eslint-disable-next-line class-methods-use-this
    getEndpointReferenceHash(endpointRef) {
      return `${endpointRef.operationId}$${endpointRef.containerNamespace}`;
    }

    /**
     * @private
     */
    _getEndpointFromPlugin(endpointRef, serverVariables = {}) {
      // do we get variables for all servers in the backend chain or just the last one?
      const backendConfig = this.catalogInfo.chain[0];
      const resolvedVariables = (backendConfig && backendConfig.variables) || {};
      const allVariables = Object.assign({}, resolvedVariables, serverVariables);

      const server = {
        url: this.server.getUrl(),
        extensions: this.extensions,
      };

      const options = {
        contextNamespace: endpointRef.containerNamespace,
        namespace: this._endpointReference.namespace,
        serviceId: this._endpointReference.serviceId,

        serverVariables: allVariables,

        url: this.catalogInfo.url,
        server,
      };
      return this._plugin.getEndpointDefinition(endpointRef.operationId, options);
    }
  }

  return PluginServiceDefinition;
});


'use strict';

define('vb/private/stateManagement/switcherBridge',[
  'knockout',
  'vb/helpers/mixin',
  'vb/private/stateManagement/switcherFlowMixin',
  'vb/private/stateManagement/componentBridge',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/navigationContext',
  'vb/private/history',
  'vb/private/utils',
  'vb/private/constants',
], (ko, Mixin, SwitcherFlowMixin, ComponentBridge, Router, NavigationContext, History, Utils, Constants) => {
  /**
   * Implementation of the switcher component
   */
  class SwitcherBridge extends ComponentBridge {
    constructor(container, props) {
      super();
      if (container.switcher) {
        throw new Error('Cannot have more than one oj-vb-switcher in a page.');
      }

      this.parent = container;
      this.parent.switcher = this;

      // This is how switcher flows are created instead of regular flows
      this.parent.createFlow = this.createFlow.bind(this);

      /**
       * Object to map switcher element by id
       * id: String
       * flowId: String
       * flow: componentFlow
       * page: String (page)
       */
      this._allFlows = {};
      this._moduleConfig = ko.observable(Constants.blankModuleConfig);
      this.props = props;

      /**
       * It is important to add a popstate event listener directly because it will be
       * handle after the ojRouter popstate. If we were using popStateCallback in Router
       * it would be executed first and the navigation will be cancelled by ojRouter.
       */
      window.addEventListener('popstate', this.handlePopStateEvent.bind(this), false);

      this.arrayAdd(props.data.data);

      if (this.currentItem) {
        const flowElt = this._allFlows[this.currentItem];
        if (flowElt) {
          this._initialize(flowElt, true);
        }
      }
    }

    /**
     * Switch the flow displayed when the current item changes.
     *
     * @param  {String}  id          The id of the switcher element to make current
     * @param  {String}  previousId  The previous switcher id
     * @param  {Boolean}  replace    When true, do not push a state change on the browser
     * @return {Promise}
     */
    currentItemChanged(id, previousId, replace) {
      this.itemChanged = {
        id,
        previousId,
      };

      return Promise.resolve().then(() => {
        let flowElt;

        if (id) {
          flowElt = this._allFlows[id];
        }

        // There is no flow matching this id in the data
        if (!flowElt) {
          return undefined;
        }

        return this._initialize(flowElt, replace);
      });
    }

    /**
     * Triggered in response of element(s) added to the array of switcher elements
     *
     * @param {Array<Object>} data The array of element(s) to add
     */
    arrayAdd(data) {
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((element) => {
          if (Utils.isObject(element) && element.id && element.flow) {
            this._allFlows[element.id] = element;
          } else {
            throw new Error('Switcher element require id and flow properties to be defined.');
          }
        });
      }
    }

    /**
     * Triggered in response of element(s) deleted from the array of switcher elements
     *
     * @param {Array<Object>} data The array of element(s) to add
     */
    arrayRemove(data) {
      if (Array.isArray(data) && data.length > 0) {
        // Only delete one element at a time
        const elementToRemove = data[0];
        if (this.itemChanged.id === null && this.itemChanged.previousId === elementToRemove.id) {
          const switcherElement = this._allFlows[elementToRemove.id];
          switcherElement.toDelete = true;

          const allIds = Object.keys(this._allFlows);
          const indexToRemove = allIds.indexOf(switcherElement.id);
          const newIndex = (indexToRemove > 0) ? indexToRemove - 1 : indexToRemove + 1;
          if (newIndex < allIds.length) {
            this.currentItem = allIds[newIndex];
          }
        }
      }
    }

    /**
     * Delete a switcher element from the _allFlow cache
     */
    delete(id) {
      if (id) {
        delete this._allFlows[id];
      }
    }

    /**
     * Called when the browser state stack changes (see constructor)
     */
    handlePopStateEvent() {
      // Retrieve potential switcher flow info from the browser history
      const flowElt = History.getVariable('internal', 'flowElt');

      // By changing the value of currentItem, the switcher will automatically switch
      if (this.currentItem !== flowElt.id) {
        this.currentItem = flowElt.id;
      } else {
        this._initialize(flowElt, true);
      }
    }

    /**
     * Retrieve the switcher component currentItem property
     *
     * @type {String}
     */
    get currentItem() {
      return this.props.currentItem;
    }

    /**
     * Change the switcher component currentItem property
     * Because it's a write-back property assignment need to be done on props.currentItem
     *
     * @type {String}
     */
    set currentItem(value) {
      this.props.currentItem = value;
    }

    /**
     * Initializes the given flow element.
     *
     * @param  {Object}  flowElt  The flow element
     * @return {Promise} A promise that resolve when the content of the switcher is set to the flow
     */
    _initialize(flowElt, replace) {
      const navParam = {
        operation: Constants.NavigateOperation.FLOW,
        flow: `${flowElt.id}:${flowElt.flow}`,
        page: flowElt.page,
        params: flowElt.params,
        history: Constants.HistoryMode.SKIP,
      };

      // Change the router state
      return this.parent.navigateOperation(navParam, new NavigationContext(this.parent, navParam))
        .then((result) => {
          if (result && result.navigated === true) {
            flowElt.started = true;
            // this.currentItem = flowElt.id;
            if (!replace) {
              History.pushState();
            }
            History.setVariable('internal', 'flowElt', flowElt);
            History.sync();
            Router.clearBusyState();
          }
        });
    }

    _isActiveFlow(id) {
      return this.currentItem && id && id === this.currentItem;
    }

    createFlow(flowId, container) {
      // Construct a Flow (either Flow or PackageFlow depending the environment) with the behavior
      // required for working in the switcher component.
      return new (Mixin(container.parent.constructor.FlowClass).with(SwitcherFlowMixin))(flowId, this, container);
    }

    /**
     * Retrieve a switcher element given its id
     *
     * @param  {String}  elementId  The element identifier
     * @return {Object}  The element data.
     */
    getElementData(elementId) {
      return this._allFlows[elementId];
    }

    /**
     * Retrieve the current switcher element
     *
     * @return {Object}  The element data.
     */
    getCurrentElementData() {
      return this.getElementData(this.currentItem);
    }

    /**
     * Listen to navigation changes inside the switcher and update the current page
     *
     * @param {string}  pagePath  The page path
     */
    updateCurrentPagePath(pagePath) {
      const activeFlowData = this.getCurrentElementData();
      // Only take the path below the active flow
      const page = pagePath.substring(pagePath.indexOf(activeFlowData.flow) + activeFlowData.flow.length + 1);
      activeFlowData.page = page;
    }

    /**
     * Override ComponentBridge.moduleConfig
     * This is how the content is changed
     *
     * @type {Object}
     */
    get moduleConfig() {
      return this._moduleConfig;
    }
  }

  return SwitcherBridge;
});


/* eslint-disable max-classes-per-file */

'use strict';

define('vbsw/private/urlMapperClient',[
  'vbc/private/log',
  'vbsw/private/utils',
  'urijs/URI',
], (Log, Utils, URI) => {
  //
  const logger = Log.getLogger('/vbsw/private/plugins/utils/urlMapperClient');

  /**
   * this mapper sends messages to the application, to get potential URL mappings from the services layer.
   */
  class ServiceUrlMapperClient {
    /**
     * @param config the response from vbGetConfiguration 'urlMapping' message.
     * this is vbConfig.SERVICE_WORKER_CONFIG.urlMapping,
     * plus applicationUrl and baseUrl from Configuration.
     */
    constructor(config) {
      let prefixesToIgnore = [];

      // make a regex to skip application resource URLs
      if (config) {
        // if SERVICE_WORKER_CONFIG.urlMapping.includeDefaultPrefixes = false, skip these
        if (config.includeDefaultPrefixes !== false) {
          if (config.applicationUrl) {
            // When using vanity URL, BASE_URL_TOKEN starts with '/'
            const isVanityUrl = config.baseUrlToken ? config.baseUrlToken[0] === '/' : false;

            // Skipping request prefixed by applicationUrl is not really necessary because
            // only the index.html request qualifies. All other requests for internal resources
            // should be fetched relative to the baseUrl. However, if applicationUrl is a vanityUrl
            // and only contains the host name, e.g., https://myCompany.com, then all requests
            // will get skipped. To fix this issue and to be safe, we will only add applicationUrl
            // to prefixesToIgnore if it's not a vanity url.
            if (!isVanityUrl) {
              // use a Request object to remove any default protocol
              prefixesToIgnore.push(new Request(config.applicationUrl).url);
            }
          }
          if (config.baseUrl && config.baseUrl !== config.applicationUrl) {
            prefixesToIgnore.push(new Request(config.baseUrl).url);
          }
          if (config.baseProxyUrl && config.baseProxyUrl !== config.applicationUrl) {
            prefixesToIgnore.push(new Request(config.baseProxyUrl).url);
          }
          if (config.baseTokenRelayUrl && config.baseTokenRelayUrl !== config.applicationUrl) {
            prefixesToIgnore.push(new Request(config.baseTokenRelayUrl).url);
          }
          if (config.modulePath) {
            prefixesToIgnore.push(new Request(config.modulePath).url);
          }
          if (config.jetPath) {
            prefixesToIgnore.push(new Request(config.jetPath).url);
          }
          // skip token relay  & proxy requests (ex: ".*/services/auth/1.1/tokenrelay/")
          const tokenRelayFrag = Utils.getTokenRelayUrl('.*', '');
          // replace the trailing slash on tokenRelay, to match either slash or end-of-string
          prefixesToIgnore.push(tokenRelayFrag.replace(/\/$/, '(\\/|$)'));
          // returned as: ".*/services/auth/1.1/proxy/.*/uri/"
          const proxyFrag = Utils.getProxyUrl('.*', '.*');
          prefixesToIgnore.push(proxyFrag);

          // BUFP-36271
          const userInfoUrl = (config.userConfig && config.userConfig.configuration
            && config.userConfig.configuration.url);

          if (userInfoUrl) {
            // use the Request to get the port
            prefixesToIgnore.push(new Request(userInfoUrl).url);
            /**
             * also, for FA deployed app, they may manipulate the URL in the security provider, so match the suffix
             * in case the host attached by Request above is different.
             * for example, in "userConfig", "url" might be:
             *   /fscmRestApi/applcoreApi/v1/fndvbcsuishell/userinfo"
             *  and Request(url) turns it into:
             *   https://slc13qyo.us.oracle.com:8082/fscmRestApi/applcoreApi/v1/fndvbcsuishell/userinfo
             * but FA FndSecurityProvider might actually fetch
             *   https://fuscdrmsmc393-fa-ext.us.oracle.com:443/fscmRestApi/applcoreApi/v1/fndvbcsuishell/userinfo?s=crm
             *
             * do not expect, or rely on, the behavior described above; just workaround the possibility of it (19.4.3).
             * Skipping the userInfo should NOT be necessary post 19.4.3, when they update catalog.json syntax.
             */
            prefixesToIgnore.push(`.*${userInfoUrl}`);
          }
        }

        // SERVICE_WORKER_CONFIG.urlMapping.prefixesToIgnore
        if (config.prefixesToIgnore && Array.isArray(config.prefixesToIgnore)) {
          prefixesToIgnore = prefixesToIgnore.concat(config.prefixesToIgnore);
        }
      }

      if (prefixesToIgnore.length) {
        this.ignoreForMapping = new RegExp(`^(${prefixesToIgnore.join('|')})`, 'i'); // really use 'i'?
      } else {
        // this is 'just in case' we don't get config for some weird reason
        this.ignoreForMapping = (config && config.ignoreRegex)
          ? new RegExp(config.ignoreRegex) : /\/catalog.json$/g;
      }
    }

    /**
     * this is the public method for getting a possible mapping.
     * A mapping is what currently appears in the 'vb-info-extension' header for a 'normal' data fetch.
     *
     * when attempting to do reverse mapping initially, we may cause additional fetches that
     * we need to allow to pass through (instead of reverse-mapping).
     * @param request
     * @param client an abstraction from JET Offline Toolkit for Utils.postMessage
     * @returns {Promise<Object | null>}
     */
    getMapping(request, client) {
      return Promise.resolve()
        .then(() => {
          if (this.ignoreForMapping.test(request.url)) {
            return null;
          }

          logger.fine(`getMapping called with: ${request.url}`);

          let timeout;

          const timeoutPromise = new Promise((resolve, reject) => {
            timeout = setTimeout(() => {
              reject(new Error(`urlMapper postMessage timeout: ${request.url}`));
              // eslint-disable-next-line no-use-before-define
            }, UrlMapperClient.MAPPER_POSTMESSAGE_TIMEOUT);
          });

          const mappingPromise = this.requestMapping(request.url, client);
          // cancel the timer, otherwise debugger may stop on the reject call above
          mappingPromise.then(() => {
            if (timeout) {
              clearTimeout(timeout);
            }
          });

          return Promise.race([mappingPromise, timeoutPromise]);
        })
        .catch((err) => {
          logger.warn('UrlMapperClient is unable to request mapping, continuing', request.url, err);
          return null;
        });
    }

    /**
     * send a message to get the mapping, apply it, and add to our local cache
     * @param url
     * @param client
     * @returns {Object}
     * @private
     */
    requestMapping(url, client) {
      let query;
      return Promise.resolve()
        .then(() => {
          if (this.disabled) {
            return null;
          }

          // normalize the url - this will remove default ports, possibly
          const uri = URI.parse(url);
          // and also remove query parameters
          query = uri.query;
          uri.query = null;
          const urlWithoutQuery = URI.build(uri).toString();

          const message = {
            method: 'vbGetUrlMapping',
            args: [urlWithoutQuery],
          };

          return Utils.postMessage(client, message);
        })
        .then((mapping) => {
          // add query parameters back
          if (query && mapping && mapping.urlToFetch) {
            const uri = URI.parse(mapping.urlToFetch);
            uri.query = query;
            // eslint-disable-next-line no-param-reassign
            mapping.urlToFetch = uri.href();
          }
          return mapping;
        });
    }
  }

  /**
   * a mapper that always returns null
   */
  class StubMapperClient {
    constructor() {
      logger.warn('URL Mapping is disabled by configuration'); // @todo: info?
    }

    // eslint-disable-next-line class-methods-use-this
    getMapping() {
      return Promise.resolve(null);
    }
  }

  /**
   * this is the 'client' used by the fetch handler plugins, for requesting a potential mapping
   * for a given URL form the UrlMapper.
   *
   * depending on whether mapping is enabled, it will create one of the classes above, to do the actual getMapping.
   *
   * note: active() must be called after construction, for this client to process requests.
   */
  class UrlMapperClient {
    constructor(config) {
      // the fetchHandler.config.urlMapping, or window.vbInitConfig.SERVICE_WORKER_CONFIG.urlMapping.
      // includes the applicationUrl and baseUrl from the vbInitConfig.SERVICE_WORKER_CONFIG.
      this.config = config ? Object.assign({}, config.urlMapping || {}, {
        applicationUrl: config.applicationUrl,
        baseUrl: config.baseUrl,
        baseUrlToken: config.baseUrlToken,
        modulePath: config.modulePath,
        jetPath: config.jetPath,
        userConfig: config.userConfig,
      }) : {};

      // do not process requests until activated
      this.activated = false;
    }

    /**
     * create the proper implementation; 'real' or 'stubbed'
     * @returns {Promise}
     * @private
     */
    init() {
      if (!this.initPromise) {
        this.initPromise = Promise.resolve()
          .then(() => {
            if (this.config.disabled) {
              this.delegate = new StubMapperClient();
            } else {
              this.delegate = new ServiceUrlMapperClient(this.config);
            }
          })
          .catch((e) => {
            logger.warn('error initializing URL Mapping, disabled');
            logger.error(e);
            this.delegate = new StubMapperClient();
          });
      }
      return this.initPromise;
    }

    /**
     * if activated, delegates to the real implementation. otherwise, returns null
     *
     * @param request
     * @param client
     * @returns {Promise<Object|null>}
     */
    getMapping(request, client) {
      return this.activated ? this.init()
        .then(() => this.delegate.getMapping(request, client))
        : Promise.resolve(null);
    }

    /**
     * wait for VB to be ready to process mapping requests; skip requests until then
     * @returns {boolean}
     */
    activate() {
      logger.info('URL Mapping is activated');
      this.activated = true;
      return this.activated;
    }
  }

  // UrmMapperClient postMessage timeout, in case something goes really wrong
  // picked 1 second arbitrarily; if it times out, we just skip waiting for a mapping, and request completes.
  UrlMapperClient.MAPPER_POSTMESSAGE_TIMEOUT = 1000;

  return UrlMapperClient;
});


'use strict';

define('vb/private/stateManagement/layoutMixin',[
  'vb/private/translations/bundlesModel',
], (
  BundlesModel,
) => {
  /**
   * Mixin that is used by layout container and extension to load translation bundles similarly
   */
  const LayoutMixin = (superclass) => class extends superclass {
    /**
     * overrides the base impl, so we can add "allowSelfRelative: false"
     * MJR: Investigate, is this correct?
     *
     * @returns {Promise<Object>} a promise that resolves with the bundles model
     */
    loadTranslationBundles() {
      if (!this.loadBundlesPromise) {
        // todo : define system vars, for substitutions in path
        this.loadBundlesPromise = BundlesModel.loadBundlesModel(this.application.runtimeEnvironment, this.definition,
          this.getResourceFolder(), { initParams: this.application.initParams, allowSelfRelative: false },
          this.extension)
          .then((bundlesModel) => {
            this.bundles = bundlesModel;
            return this.bundles;
          });
      }
      return this.loadBundlesPromise;
    }
  };

  return LayoutMixin;
});


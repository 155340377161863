'use strict';

define('vb/private/stateManagement/fragmentHolderMixin',[
  'vb/components/oj-vb-fragment/loader', // load fragment CCA so it's automatically available
], () => {
  /**
   * Mixin that should be used by all containers (including package or extension based) that can contain fragments.
   * Generally this class extends Container. See Page, Layout or Fragment for example
   */
  const FragmentHolderMixin = (superclass) => class extends superclass {
    constructor(id, parent, className) {
      super(id, parent, className);
      /**
       * instances of loaded fragments used by this container.
       * @type {Object}
       */
      this.fragments = {};

      /**
       * bridge that connects this container to the component
       * @type {null}
       * @private
       */
      this._bridge = null;
    }

    get bridge() {
      return this._bridge;
    }

    set bridge(fragmentBridge) {
      this._bridge = fragmentBridge;
    }

    /**
     * Retrieves a fragment instance for the fragment id. If a Fragment instance cannot be located this method
     * creates the Fragment instance first and then returns the fragment context. This is only called from the
     * FragmentBridge.
     * @param {string} id of the fragment
     * @param {string} name of the fragment (artifacts)
     * @param {object} params input from caller
     */
    createFragment(id, name, params) {
      let fragment = this.fragments[id];
      // if name and/or params are provided then we definitely construct a fragment, otherwise return the fragment
      // that was previously created or undefined.
      if (!fragment && name) {
        return this.getFragmentClass().then((fragmentClazz) => {
          fragment = new (fragmentClazz)(id, this, undefined, name, params);
          this.fragments[id] = fragment;
          return fragment;
        });
      }

      return Promise.resolve(fragment);
    }

    getFragment(id) {
      return this.fragments[id];
    }

    // eslint-disable-next-line class-methods-use-this
    getFragmentClass() {
      return Promise.resolve(this.constructor.FragmentClass);
    }

    disposeFragments() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });
    }

    /**
     * deletes a fragment from the container.
     * @param id
     */
    deleteFragment(id) {
      delete this.fragments[id];
    }
  };

  return FragmentHolderMixin;
});


'use strict';

/**
 * the versions.json is generated in dist/packages, requires using our grunt tasks to generate.
 */
define('vb/versions',['text!vbVersions/versions.json'], (versions) => {
  const vb = JSON.parse(versions);

  (function () {
    window.vb = vb.visualDevelopmentPlatform;
    window.vb.jet = vb.jet;
    window.vb.workbox = vb.workbox;
  }());

  return vb;
});


'use strict';

define('vb/private/history',['vb/private/utils', 'vb/private/constants', 'urijs/URI',
], (Utils, Constants, URI) => {
  const VB_STATE_PROPERTY = 'vbState';
  const REPLACE_STATE_OP = 'replaceState';
  const PUSH_STATE_OP = 'pushState';
  let cachedState;
  let uri;
  let stateOp;
  let urlParameters;
  let navBackParams;
  let stateBeforeHistoryPop = null;
  let skipMode;

  /**
   * The History class has 3 purposes:
   *   1) abstract the window.history API
   *   2) defer any updates to the browser history until sync is called to minimized the amount
   *      of change to the browser history
   *   3) get around Google chrome throttling of the history state
   *
   * The state for VB is stored in a sub-property named 'vbState' to not conflict with other
   * state also stored in the browser history.
   */
  class History {
    /**
     * initialize the History class.
     *   - cached state is empty
     *   - any URL params are copied internally if clearParameter is truthy
     *   - URL params are removed from the search
     *   - operation is set to replaceState
     * @param  {boolean} clearParameters if true, URL params are not stored internally
     * @param  {function} popStateCallback register a callback on the history popstate event
     */
    static init(clearParameters, popStateCallback) {
      // Add a listener to the popstate event so when navigating using the browser back button
      // or when history.back() is called we know to update the cached state
      window.onpopstate = (event) => {
        History.popStateEventHandler(event);
        if (popStateCallback) {
          popStateCallback();
        }
      };

      cachedState = {};
      uri = new URI();
      // Make a copy of the original query param and clean up the URL
      // This is so query param that are not part matching any fromUrl variable are removed
      // from the URL when that application is started
      if (!clearParameters) {
        urlParameters = URI.parseQuery(uri.search());
      }
      uri.search('');
      stateOp = REPLACE_STATE_OP;
      skipMode = false;
    }

    /**
     * A listener for the popstate event so when navigating using the browser back button
     * or when history.back() is called we know to update the cached state.
     *
     * @param  {Object} event the popState event
     */
    static popStateEventHandler(event) {
      // Make a copy of the state before changing it. It will be used to restore state and url
      // if the navigation is cancelled.
      stateBeforeHistoryPop = {
        state: History.state,
        url: uri.href(),
      };

      // It is possible for event.state to be null, for example if a previous pushState used
      // null for the state argument or when a link with href="#" is clicked
      if (event.state === null) {
        const oldUrl = new URI(uri).hash('');
        const newUrl = new URI().hash('');
        const samePage = newUrl.equals(oldUrl);

        // Do not loose internal state if navigating to the same page
        if (!samePage) {
          History.replaceState(null);
        }
      } else {
        History.replaceState(event.state[VB_STATE_PROPERTY]);
      }

      // Merge navBackParams on top of the cachedState inputParameters
      // This is to properly handle input parameters coming from the navigateBack action
      if (navBackParams) {
        cachedState.inputParameters = cachedState.inputParameters || {};
        Utils.cloneObject(navBackParams, cachedState.inputParameters);
        navBackParams = undefined;
      }

      // Create a new URI object using the existing browser URL
      uri = new URI();
      // Repopulate the url params that will be used by manageInputParameters
      urlParameters = URI.parseQuery(uri.search());
      stateOp = REPLACE_STATE_OP;
    }

    /**
     * Reset the pathname part of the current uri.
     * This is needed after the router navigated event to update the uri
     * with the new path set by the router. Input parameter need to be
     * kept around to initialize the fromUrl variable.
     */
    static resetUri() {
      if (!skipMode) {
        const search = History.getSearch();
        const hash = History.getHash();
        uri = new URI();
        History.setSearch(search);
        History.setHash(hash);
      }
    }

    /**
     * Reset the URL parameters.
     * This is used before navigating to clean up for the new state.
     */
    static resetUrlParameters() {
      if (!skipMode) {
        History.setSearch('');
        urlParameters = {};
      }
    }

    static setSkipMode(flag) {
      skipMode = flag;
    }

    static getSkipMode() {
      return skipMode;
    }

    /**
     * Retrieve the current cached state. Never returns undefined or null.
     *
     * @return {Object} the current state
     */
    static get state() {
      return Utils.cloneObject(cachedState);
    }

    /**
     * Set the browser state operation to be pushState
     */
    static pushState() {
      stateOp = PUSH_STATE_OP;
    }

    /**
     * Replace the currently cached browser state and URL.
     * @param  {Object} state the new state
     */
    static replaceState(state) {
      cachedState = state ? Utils.cloneObject(state) : {};
    }

    static setUrlParameters(urlParams) {
      if (!skipMode) {
        uri.addSearch(urlParams);
      }
    }

    static addUrlParameter(name, value) {
      if (!skipMode) {
        if (value !== undefined) {
          uri.addSearch(name, value);
        }
      }
    }

    static setUrlParameter(name, value) {
      if (!skipMode) {
        if (value !== undefined) {
          uri.setSearch(name, value);
        } else {
          uri.removeSearch(name);
        }
      }
    }

    static getUrlParameter(name) {
      return urlParameters[name];
    }

    /**
     * Retrieve the search section of the current URI
     * @return {String}
     */
    static getSearch() {
      return uri.search();
    }

    /**
     * Replace the search section of the current URI
     * @param {String} search
     */
    static setSearch(search = '') {
      if (!skipMode) {
        uri.search(search);
      }
    }

    /**
     * Retrieve the hash section of the cached URI
     * @return {String} hash
     */
    static getHash() {
      return uri.hash();
    }

    /**
     * Replace the hash section of the cached URI
     * @param {String} hash
     */
    static setHash(hash = '') {
      uri.hash(hash);
    }

    /**
     * Store a copy of the input parameters in history.
     * It is the options.params argument of the navigateToPage action. This is needed
     * in two situation:
     *   1) When navigating back to this page and initializing the page input parameter.
     *   2) When navigating to the same page to compare if the input parameters are different.
     *
     * @param {Object} inputParameters an object where the property keys are params name and
     * the property values the params values.
     */
    static setInputParameters(inputParameters) {
      cachedState.inputParameters = {};
      Utils.cloneObject(inputParameters, cachedState.inputParameters);
    }

    static getInputParameters() {
      return cachedState.inputParameters || {};
    }

    /**
     * Set the navBackParams object.
     * This is used by the navigateBackAction to set input parameters on the page it is
     * navigating back to.
     * @param {Object} params
     */
    static setNavNavBackParams(params) {
      navBackParams = params;
    }

    static setPagePath(pagePath) {
      cachedState.pagePath = pagePath;
    }

    static getPagePath() {
      return cachedState.pagePath;
    }

    /**
     * Used to store history persisted variables.
     * Variable state is stored in state.variables[${namespace}.${name}]
     *
     * @param {String} namespace
     * @param {String} name
     * @param {*} value
     */
    static setVariable(namespace, name, value) {
      const key = `${namespace}.${name}`;

      if (value !== undefined) {
        if (!cachedState.variables) {
          cachedState.variables = {};
        }
        cachedState.variables[key] = value;
      } else if (cachedState.variables) {
        delete cachedState.variables[key];
      }
    }

    /**
     * Used to retrieve history persisted variables
     *
     * @param  {String} namespace
     * @param  {String} name
     * @return {*}
     */
    static getVariable(namespace, name) {
      const key = `${namespace}.${name}`;
      return cachedState.variables && cachedState.variables[key];
    }

    static getVariables() {
      return cachedState.variables || {};
    }

    /**
     * Execute the back on browser history API
     */
    static back() {
      window.history.back();
    }

    /**
     * Apply cached changes to the browser history or URL.
     * @return {Promise} a promise that resolves when the browser history changed
     */
    static sync() {
      const href = uri.href();

      stateBeforeHistoryPop = null;

      if (stateOp === REPLACE_STATE_OP) {
        let historyState = window.history.state;
        const vbState = historyState && historyState[VB_STATE_PROPERTY];

        if (href !== window.location.href || Utils.diff(cachedState, vbState)) {
          // An empty cacheState is the same as undefined
          if (Object.keys(cachedState).length > 0) {
            if (!historyState) {
              historyState = {};
            }
            // Our state is always store in the property 'vbState' of the history state
            historyState[VB_STATE_PROPERTY] = cachedState;
          } else if (historyState) {
            delete historyState[VB_STATE_PROPERTY];
          }

          return Utils.changeBrowserState(historyState, href, stateOp);
        }
        return Promise.resolve();
      }
      // In the pushState case, don't need to merge with existing state
      return Utils.changeBrowserState({ [VB_STATE_PROPERTY]: cachedState }, href, stateOp).then(() => {
        stateOp = REPLACE_STATE_OP;
      });
    }

    /**
     * Restore the URL and the history state to the value before the browser back/forward button.
     * This is to be used when a navigation initiated by a browser back/forward button is cancelled.
     * @return {Promise} a promise that resolve when the browser history is restored
     */
    static restoreStateBeforeHistoryPop() {
      // Only restore the state if the the navigation was initiated from a browser back/forward button
      if (stateBeforeHistoryPop) {
        cachedState = stateBeforeHistoryPop.state;
        uri = new URI(stateBeforeHistoryPop.url);
        return History.sync();
      }

      return Promise.resolve();
    }

  // end of history class
  }

  return History;
});


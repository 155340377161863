'use strict';

define('vb/private/helpers/abstractRestHelper',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/services/uriTemplate',
  'vb/private/services/transformsUtils',
  'vb/private/services/swaggerUtils',
  'vb/private/services/endpointReference',
  'vbc/private/trace/tracer',
], (Constants, Utils, Log, LogConfig, UriTemplate, TransformsUtils, SwaggerUtils, EndpointReference, Tracer) => {
  const logger = Log.getLogger('/vb/private/helpers/abstractRestHelper', [
    // Register custom loggers
    {
      name: 'startRest',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.restHelperStart,
    },
    {
      name: 'endRest',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.restHelperEnd,
    },
  ]);

  // match 'application(any number of word or / or .)json. Example
  // application/vnd.oracle.adf.resourceitem+json
  const APP_JSON_CONTENT_TYPE_REGEX = /application[\w/+.]*json/;
  const REQUEST_TRANSFORMS_TYPE_BODY = 'body';

  /**
   * Helper to make a REST call. This class is loosely based on the fetch API
   * proposal (https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API).
   *
   * The result of calling fetch is the Response object as defined here:
   * https://developer.mozilla.org/en-US/docs/Web/API/Response
   *
   * If a service endpoint has transformation functions defined, the fetch will be wrapped prior
   * and after to call those transformation functions.
   *
   * This is a BASE class wghich contains all PRIVATE methods; the public methods are in rest.js
   *
   * This instance is reusable and fetch can be called multiple times after it has been
   * configured.
   */
  class AbstractRestHelper {
    /**
     * Creates a new AbstractRestHelper instance.
     *
     * the 'endpointReference' is an opaque reference to an 'endpoint', and can be any type.
     * This abstract class must treat it as such; the endpointReference is interpreted by the EndpointProvider.
     *
     * endpointProvider is used internally, and is a path to a an object with a getEndpoint() method.
     *
     * @private
     * @abstract
     *
     * @param {any} endpointReference
     * @param {string} endpointProvider requirejs module to use for getting the endpoint implementation
     * @param {Container|undefined} container
     *
     */
    constructor(endpointReference, endpointProvider, container) {
      this.log = logger;

      // Needs to default to undefined instead of null because its passed to methods that have default values
      // (which are only applied when the argument is not specified or undefined).
      this.container = container && typeof container.extensionId === 'string' && container.extensionId.length > 0
        ? container
        : undefined;
      if (!this.container) {
        logger.warn('RestHelper created without the container information', endpointReference);
      }

      // The name of this variable comes from RestHelper.get(...)
      this._endpointId = endpointReference;

      this.endpointReference = (typeof endpointReference === 'string')
        ? new EndpointReference(endpointReference, this.container)
        : endpointReference;

      this.id = `${Utils.generateUniqueId()}`;
      this.initConf = {};
      this.params = {};
      this.bdy = null;
      this.transformRequestFuncMap = {};
      this.transformResponseFuncMap = {};
      this.transformRequestOptionsMap = {};
      this.initRequestMap = null;
      this.defaultRequestContentType = null;
      this.handler = null;
      this.url = null;
      this.retryCount = 0;

      // Stores the information about server urls and is passed to the prepare transform.
      this._serverUrlInfos = [];

      this.endpointProvider = endpointProvider; // requirejs path to the endpoint provider.
    }

    /**
     * a default implementation
     * @returns {string}
     */
    getName() {
      return JSON.stringify(this.endpointReference);
    }

    /**
     * Stringify JSON body
     * @param body
     * @returns {*}
     * @private
     */
    stringifyBody(body) {
      if (Array.isArray(body) || (Utils.isObject(body) && Utils.isPrototypeOfObject(body))) {
        this.defaultRequestContentType = 'application/json';
        return JSON.stringify(body);
      }
      return body;
    }

    /**
     * do any last-minute header processing, just before we create the Request object
     * @param configHeaders
     * @returns {Headers}
     */
    static processFinalHeaders(configHeaders) {
      // if content-type is exactly "multipart/form-data" and the body is FormData, remove the header
      // so the browser sets the header with boundary markers correctly
      // use the Headers object for case-insensitivity of the name
      const headers = new Headers(configHeaders || {});
      const contentType = headers.get(Constants.Headers.CONTENT_TYPE);
      if (contentType === Constants.ContentTypes.MULTIPART) {
        headers.delete(Constants.Headers.CONTENT_TYPE);
      }

      return headers;
    }

    /**
     * Return the body of the response based on the content type.
     *
     * @private
     * @param response
     * @param bodyFormat optional, see @AbstractRestHelper.responseBodyFormat
     * @returns {Promise|*} Body data
     * @private
     */
    static getBody(response, bodyFormat) {
      if (!response) {
        return Promise.resolve(null);
      }

      const contentType = response.headers.get(Constants.Headers.CONTENT_TYPE);

      // an override for the content-type
      let conversionPromise;
      switch (bodyFormat) {
        case 'json':
          conversionPromise = response.json();
          break;
        case 'arrayBuffer':
          conversionPromise = response.arrayBuffer();
          break;
        case 'blob':
          conversionPromise = response.blob();
          break;
        case 'text':
          conversionPromise = response.text();
          break;
        case 'base64':
          // eslint-disable-next-line no-underscore-dangle
          conversionPromise = AbstractRestHelper._getBase64(response, 'readAsDataURL')
            .then((url) => url.split(',')[1]);
          break;
        case 'base64Url':
          // eslint-disable-next-line no-underscore-dangle
          conversionPromise = AbstractRestHelper._getBase64(response, 'readAsDataURL');
          break;
        case 'formData':
          conversionPromise = response.formData();
          break;
        default:
          // continue, look for content-type
      }

      if (conversionPromise) {
        return conversionPromise
          // eslint-disable-next-line max-len
          .catch((error) => Promise.reject(new Error(`An error occurred while using '${bodyFormat}' to convert the response (${contentType}):\n${error.message || error}`)));
      }

      // if it is missing, assume text?
      if (!contentType) {
        return response.text();
      }

      // this is a slight hack since ADFm uses odd application/json type content types - we should
      // probably rationalize this by having some default registry somewhere (possibly constants)
      if (contentType.indexOf('json') > 0) {
        // if the response indicates an error, guard against bad (FA/RAMP) services that
        // have a 'json' content-type, but actually contain a text error (BUFP-21185).
        // Return the error from the response, unaltered (only cloning on !ok for performance)
        const clone = response.ok ? null : response.clone();

        return response.json().catch((e) => {
          const errMsg = clone
            // eslint-disable-next-line max-len
            ? `The response specifies content type ${contentType}, but unable to parse response as JSON. Reading response as text`
            : `Unable to parse response as JSON, content type ${contentType}`;
          const errMsgWithErr = `${errMsg} : ${e}`;

          logger.error(errMsg, e);
          return clone
            ? clone.text().then((text) => text || errMsgWithErr)
            : errMsgWithErr; // return the body text, if there is any
        }).catch((e) => {
          logger.error('error getting response as text', e);
          return `unable to get response as text: ${e}`;
        });
      }

      // simple check for 'image' types, return a Blob
      if (contentType === 'application/octet-stream' || contentType.startsWith('image/')) {
        return response.blob();
      }

      // TODO support additional content types
      return response.text();
    }

    /**
     * read the body as a Blob, and then use FileReader to convert the results
     * @param body from the fetch
     * @param methodName FileReader method to use, uses readAsArrayBuffer if no function by the specified name
     * @private
     * @return {Promise}
     */
    static _getBase64(body, methodName) {
      return body.blob().then((blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reject
        reader.onerror = reject;
        reader.onabort = reject;
        // resolve
        reader.onload = () => {
          resolve(reader.result);
        };
        if (typeof reader[methodName] === 'function') {
          reader[methodName](blob);
        } else {
          reader.readAsArrayBuffer(blob);
        }
      }));
    }

    /**
     * Gets the fetch configuration passed to transforms functions via the configuration parameter. Custom Rest
     * helpers may override this method to provide additional contextual information regarding the fetch call.
     * Otherwise this method is a noop.
     * Transforms functions can lookup fetch configuration under configuration.fetchConfiguration, where
     * configuration is the first parameter passed to the transform function.
     *
     * This is used internally; not a public API
     *
     * @package
     */
    // eslint-disable-next-line class-methods-use-this
    getFetchConfiguration() {
      return this.fetchConfiguration;
    }

    /**
     * fetch configuration that this Rest helper instance pertains to. Usually this object has the following
     * information:
     * - context: state of the SDP at the time fetch call was made
     * - externalContext: state of the external object, like a RestAction at the time the fetch call was made. This
     *    may not be present or have meaningful information for implicit fetches made by the SDP
     * - fetchParameters: parameters passed into the fetch call by external callers, such as a component.
     * - capability: fetch capability being used. Example fetchFirst / fetchByKeys etc.
     *
     * This is used internally; not a public API
     *
     * @package
     */
    setFetchConfiguration(config) {
      this.fetchConfiguration = config;
    }

    /**
     * depending on capabilities not all transforms need to be run
     * @param configuration
     * @returns {string[]}
     */
    // eslint-disable-next-line no-unused-vars
    getRequestTransformsToRun(configuration) {
      const transformFuncKeys = Object.keys(this.transformRequestFuncMap);
      // call body last and exclude 'fetchByKeys' as these are not used by RestAction / RestHelper
      return transformFuncKeys
        .filter(TransformsUtils.excludeFetchByKeys)
        .sort(TransformsUtils.bodyTransformLast);
    }

    /**
     * Executes the request vbPrepare transformation function, which must be done before all other transforms because
     * it allows the function to modify the parameters passed to this Rest (and all server variables) what ultimately
     * modifies the endpoint url.
     *
     * Because of the "initialization" nature of this transform, the 'configuration' and 'options' arguments are
     * a different from the others transformation functions.
     *
     * @param {Endpoint} endpoint
     * @param {Object} transformsContext a context object that is passed to every transform
     * function to store/retrieve any contextual information for the current request lifecycle.
     *
     * @returns {Promise<Endpoint>} either the endpoint passed or a new one if the vbPrepare transform changes the
     *          server variables.
     * @private
     */
    _executeRequestVBPrepareTransform(endpoint, transformsContext) {
      if (this.transformRequestFuncMap) {
        const transformFuncKeys = this.getRequestTransformsToRun({});
        if (transformFuncKeys.includes('vbPrepare')) {
          const func = this.transformRequestFuncMap.vbPrepare;

          // We don't want this transform to run twice.
          delete this.transformRequestFuncMap.vbPrepare;

          // Each element of this array is an object that has the server url template and the variables (an object
          // in which the properties are the name of the variables and the property value is the actual value used
          // by RT for that variable) used on the template. The actual value may come from the OpenAPI definition
          // (the variable default value), from the vbInitParams, or from the params passed to this helper.
          //
          // There may be more than one serverUrlTemplate because a service may have server variables, then its
          // backend may have server variables, then the backend of the backend may have server variables, ...
          //
          // This information (the values of the server variables in particular), has been requested by some teams,
          // because they want to write conditional logic predicated on it.
          //
          // See tests/services/mocked/vbPrepareTransformSpec.js line 301 for an example of this array.
          const serverUrlTemplates = this._serverUrlInfos
            .filter(({ url, template }) => url !== template)
            .map(({ template, variables }) => ({ template, variables }));

          const configuration = {
            // The actual value used to construct this instance, typically the first argument to RestHelper.get(...).
            // This was also requested by teams so they write conditional logic.
            endpointId: this._endpointId,

            // The endpoint path (or the key holding the OpenAPI operation object).
            // This was also requested by teams so they write conditional logic.
            endpointPath: endpoint.path,

            // The fetchConfiguration like the other request transforms.
            fetchConfiguration: this.getFetchConfiguration(),

            // See above.
            serverUrlTemplates,
          };

          if (!this.params) {
            this.params = {};
          }

          const options = {
            // The actual, live object containing the parameters passed to this helper - so the transform can
            // actually change the values.
            // See below.
            parameters: this.params,
          };

          // At this point, only the server variables from this.params were used - so we haven't consumed the
          // path and query parameters for example. As such we need to detect if the transform has modified the
          // former group in order to recompute the endpoint url.

          let beforeServerVariables = SwaggerUtils.splitVariables(this.params).serverVariables;
          if (beforeServerVariables) {
            beforeServerVariables = Utils.cloneObject(beforeServerVariables, {});
          }

          func.call(null, configuration, options, transformsContext);

          // If the server variables were changed, we need to recompute the endpoint metadata.
          if (Utils.diff(beforeServerVariables, SwaggerUtils.splitVariables(this.params).serverVariables)) {
            logger.info(
              'The prepare transform has changed the server variables so the endpoint',
              this._endpointId,
              'will be resolved again',
            );

            this._endpointPromise = undefined;
            return this._getEndpoint();
          }
        }
      }
      return Promise.resolve(endpoint);
    }

    /**
     * Executes the request transformation functions in order.
     * @param {Object} transformsContext a context object that is passed to every transform
     * function to store/retrieve any contextual information for the current request lifecycle.
     *
     * @returns {*}
     * @private
     */
    _executeRequestTransformations(transformsContext) {
      let customTransformChangedUrl = false;

      let configuration = {
        url: this.url,
        readOnlyParameters: this.parameters, // todo: parameters is a method, this doesn't look right,
        endpointDefinition: this.endpointMetadata,
        initConfig: {},
        fetchConfiguration: this.getFetchConfiguration(),
      };
      Object.assign(configuration.initConfig, this.initRequestMap);

      // if there is a body set and it's a string and its Content-type is application/json then
      // JSON.parse it before passing to transforms
      if (APP_JSON_CONTENT_TYPE_REGEX.test(this.defaultRequestContentType)) {
        const configBody = configuration.initConfig.body;
        let jsonBody;
        if (configBody) {
          try {
            jsonBody = typeof configBody === 'string' ? JSON.parse(configBody) : configBody;
          } catch (e) {
            this.log.error('AbstractRestHelper', this.id, 'error parsing JSON body', configBody,
              'with content-type', this.defaultRequestContentType);
          }
        }

        configuration.initConfig.body = jsonBody || configBody;
      }

      if (this.transformRequestFuncMap) {
        const transformFuncKeys = this.getRequestTransformsToRun(configuration);
        transformFuncKeys.forEach((functionName) => {
          const func = this.transformRequestFuncMap[functionName];
          const options = this.transformRequestOptionsMap[functionName];
          if (func) {
            const urlBefore = configuration.url;

            configuration = func.call(null, configuration, options, transformsContext);

            // BUFP-30950 check if a non-built-in transform changed the URL, and if so, encode query params
            customTransformChangedUrl = customTransformChangedUrl
              || ((urlBefore !== configuration.url)
                && !AbstractRestHelper.transformDoesQueryEncoding(functionName, func));

            if (!configuration || !configuration.url || !configuration.initConfig) {
              throw new Error('Transformation function did not return a configuration.');
            }
          }
        });
      }

      // transforms could have changed the body, stringify it before returning
      const { body } = configuration.initConfig;
      if (body) {
        configuration.initConfig.body = this.stringifyBody(body);
      }

      if (customTransformChangedUrl) {
        logger.info(`custom transforms have changed the URL, encoding parameters: ${configuration.url}`);
        // we need to decode/encode query params
        configuration.url = UriTemplate.encode(configuration.url, false); // false: skip path encoding
      }

      // return the new transformed configuration
      return configuration;
    }

    /**
     * Executes the response transformation functions in order.
     *
     * @param response
     * @param body
     * @param transformsContext a context object that is provided to every transform functions to
     * store/retrieve any contextual information for the current request lifecycle.
     * @returns {{}}
     * @private
     */
    _executeResponseTransformations(response, body, transformsContext) {
      const configuration = {
        headers: response.headers,
        body,
        fetchConfiguration: this.getFetchConfiguration(),
      };

      const transformResults = {};

      if (this.transformResponseFuncMap) {
        const transformFuncKeys = Object.keys(this.transformResponseFuncMap);
        // call body at the very end
        transformFuncKeys.sort((a) => (a === REQUEST_TRANSFORMS_TYPE_BODY ? 1 : 0));
        transformFuncKeys.forEach((functionName) => {
          const func = this.transformResponseFuncMap[functionName];
          if (func) {
            transformResults[functionName] = func.call(null, configuration, transformsContext);
          }
        });
      }

      return transformResults;
    }

    /**
     * Function that returns an Endpoint for the given endpoint reference.
     * @callback getEndpointCallback
     * @param {any} endpointRef
     * @param {Container} container
     * @param {any} serverVars
     * @returns {Promise<*>}
     */
    /**
     * returns a Promise that resolves to an object that implements getEndpoint(id).
     * Called internally, only once, to get the endpoint.
     * @returns {Promise<{ getEndpoint: getEndpointCallback }>}
     * @package
     */
    // eslint-disable-next-line class-methods-use-this
    _getEndpointProvider() {
      return Utils.getResource(this.endpointProvider);
    }

    /**
     * Adds a serverUrlInfo that is cached in this helper.
     *
     * @package {{ url: string, template: string, variables: object}} serverUrlInfo
     * @private
     */
    _addServerUrlInfo(serverUrlInfo) {
      // Only add the serverUrlInfo if a similar info is not already cached.
      if (this._serverUrlInfos.every(({ url, template }) => url !== serverUrlInfo.url
        && template !== serverUrlInfo.template)) {
        this._serverUrlInfos.push(serverUrlInfo);
      }
    }

    /**
     * wrapper for ServicesManager.getEndpoint, set internal Promise
     * @returns {Promise|*}
     * @private
     */
    _getEndpoint() {
      if (!this._endpointPromise) {
        this._endpointPromise = this._getEndpointProvider()
          .then((provider) => {
            // We are using the serverVariables object returned by SwaggerUtils.splitVariables as a way to
            // "piggyback" the serverUrlInfo.
            //
            // The rationale:
            // - The serverUrlInfo (server resolved url, server url template, and the server variables actual values)
            //   are very specific to this helper and should not be stored on definitions and metadata that are cached.
            // - The 'serverVariables' object is propagated as argument to several methods
            //   (search for ',serverVariables' to have a better idea). Adding yet another argument to accomplish this
            //   need would be too disruptive.
            // - When appropriate, the code dealing with the serverVariables will use the method below to add the
            //   serverUrlInfo
            // - We are using the global symbol 'RestHelper.addServerUrlInfo' because we need to make sure that the
            //   addServerUrlInfo method does not conflate with actual variables (moreover, it should not appear when
            //   clients execute Object.keys(serverVariables).
            const serverVariables = SwaggerUtils.splitVariables(this.params).serverVariables || {};
            serverVariables[Symbol.for('RestHelper.addServerUrlInfo')] = (...args) => this._addServerUrlInfo(...args);

            return provider.getEndpoint(
              this.endpointReference,
              this.container,
              serverVariables,
            );
          });
      }
      return this._endpointPromise;
    }

    /**
     * Called right before a fetch request is made, creates the initRequestMap, that is a clone
     * with headers, body, url. Also sets up
     * the transform  functions, if they haven't been created yet.
     *
     * @private
     * @returns {Promise} resolved with this.initRequestMap
     */
    _initFetchRequestMapAndUrl(transformsContext) {
      let endpoint;
      if (!this.initRequestMap) {
        return this._getEndpoint()
          .then((ep) => {
            endpoint = ep;

            if (!endpoint) {
              throw Error(`unable to find endpoint '${JSON.stringify(this.endpointReference)}'`
                + 'attempted REST call failed');
            }

            // getting these early...
            this.requestTransformationFunctions(Object.assign({},
              endpoint.getRequestTransforms(), this.transformRequestFuncMap));
            this.responseTransformationFunctions(Object.assign({},
              endpoint.getResponseTransforms(), this.transformResponseFuncMap));

            return this._executeRequestVBPrepareTransform(endpoint, transformsContext)
              .then((ep2) => {
                endpoint = ep2;
                return endpoint.getConfig(this.params);
              });
          })
          .then((config) => {
            const headers = Object.assign({}, config.headers);

            // copy over custom headers from the endpoint configuration
            const defaults = {
              method: config.method,
              body: this.bdy,
              credentials: 'same-origin',
              headers,
            };

            const contentType = this._getRequestContentType(config, defaults.body);

            // make sure headers are a copy
            // clone request configuration
            this.initRequestMap = Object.assign({}, defaults, this.initConf);
            if (contentType) {
              // set only if we don't have one already; let Headers handle case-insensitivity
              if (!new Headers(headers).get(Constants.Headers.CONTENT_TYPE)) {
                headers[Constants.Headers.CONTENT_TYPE] = contentType;
              }
            }

            // TODO for everything that we want to merge, we will need to handle that specifically
            this.initRequestMap.headers = Object.assign({}, headers, this.initConf.headers || {});

            // Object.assign(this.initRequestMap.headers, defaults.headers, this.initConf.headers);

            // todo: should this be the encoded one?
            this.url = config.url;
            this.endpointMetadata = endpoint.getMetadata();

            return this.initRequestMap; // eslint consistent-return
          });
      }

      return Promise.resolve(this.initRequestMap);
    }

    /**
     * do some simple checks to try to figure out what content-type to use,
     * and honor the "consumes" from the OpenAPI 2.0, or the request bodies "content" types (3.0)
     *
     * if we have a body, and there is a "requestContentTypes" type array for the endpoint
     *  - if the existing defaultRequestType is application*json
     *  --- look for a requestContentTypes[x] that is also application*json, and use that
     *
     *  - if we do not have an existing defaultContentType
     *  --- just take the first "requestContentTypes", assuming the body matches the requestContentTypes[0]
     *  (if the body was JSON, we should have set the defaultContentType)
     *
     * @param endpointConfig
     * @param body
     * @returns {string|null}
     * @private
     */
    _getRequestContentType(endpointConfig, body) {
      let contentType = this.defaultRequestContentType;
      // if we have a body, and the endpoint has a 'requestContentTypes' in the swagger,
      // (possibly) override the context-type
      if (this.bdy && endpointConfig.requestContentTypes && endpointConfig.requestContentTypes.length > 0) {
        if (contentType) {
          // if we already have a JSON type...
          const regexp = APP_JSON_CONTENT_TYPE_REGEX;
          if (regexp.test(this.defaultRequestContentType)) {
            endpointConfig.requestContentTypes.some((mediaType) => {
              const isJson = regexp.test(mediaType);
              if (isJson) {
                contentType = mediaType;
              }
              return isJson;
            });
          } else { // we have a default type, but its not a application*json type
            // use the first consume, since their content type could accept json but not have anything
            // to do with json in the name – and we have no choice.
            // note: at the time this method was created, this case would not happen; we only
            // ever have application/json as the default.
            contentType = endpointConfig.requestContentTypes[0];
          }
        } else if (body instanceof FormData
          && endpointConfig.requestContentTypes.indexOf(Constants.ContentTypes.MULTIPART) >= 0) {
          // we don't have a default media type, but we have a body of FormData
          contentType = Constants.ContentTypes.MULTIPART;
        } else {
          // if the body was JSON, the default media type should already have been set,
          // so assume that the body matches the first requestContentTypes, for now.
          // todo: just take the first one? what if there is more than one?
          contentType = endpointConfig.requestContentTypes[0];
        }
      }

      return contentType;
    }

    /**
     * checks if the transform is a rampTransform function
     * @param name
     * @param fn
     * @returns {boolean}
     */
    static transformDoesQueryEncoding(name, fn) {
      return fn && fn.doesQueryEncoding;
    }

    /**
     * Fetches the specified request using <tt>window.fetch</tt>.
     *
     * @param {Request} request
     * @returns {Promise<Response>}
     */
    _fetchRequest(request) {
      return Utils.getRuntimeEnvironment()
        .then((runtimeEnvironment) => {
          const doFetch = (req) => {
            runtimeEnvironment.annotate(req, 'data-request', true);
            return fetch(req);
          };

          // Inject trace context, if applicable
          // Note that this just a passthrough if the URL is blacklisted
          return Tracer.inject(request)
            // make the native request
            .then((req) => doFetch(req))
            .catch((err) => {
              // May fail if Access-Control-Allowed-Headers isn't set correctly on the server
              // In this case, blacklist the URL and fetch with the original headers
              this.log.warn('injected fetch failed, blacklisting url and reverting to original request', err);

              // Don't try to inject again
              Tracer.blacklist(request);

              // Fetch the un-injected request
              return doFetch(request);
            });
        });
    }
  }

  return AbstractRestHelper;
});


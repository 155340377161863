'use strict';

define('vb/private/vx/extensionAdapterFactory',['vb/private/utils',
], (Utils) => {
  /**
   * Factory class for retrieving the class name for the extension adapter
   */
  class ExtensionAdapterFactory {
    /**
     * @param {String} version the extension manager version
     * returns the Adapter resource used for working with the Extension Registry
     */
    static get(registryUrl, version = 'v1') {
      // Depending on the format of the URL, use different adapter class
      let adapterName = 'baseExtensionAdapter';

      registryUrl = registryUrl.trim();
      if (registryUrl.startsWith('http')) {
        // TODO implement a PWA adaptor by checking PwaUtils.isPwaConfig(window.vbInitConfig)
        adapterName = 'remoteExtensionAdapter';
      } else if (registryUrl) {
        adapterName = 'localExtensionAdapter';
      }

      return Utils.getResource(`vb/private/vx/${version}/${adapterName}`)
        .then((Adapter) => new Adapter(registryUrl));
    }
  }

  return ExtensionAdapterFactory;
});


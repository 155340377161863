'use strict';

define('vb/binding/expression',[
  'knockout',
  'vb/private/log',
  'vb/private/utils',
], (ko, Log, Utils) => {
  const logger = Log.getLogger('/vb/binding/expression');

  // Check for {{..}} and [[..]] at the beginning of strings to avoid matching
  // any usages mid string
  const ATTR_EXP = /^(?:\{\{)([^]+)(?:\}\})$/;
  const ATTR_EXP_RO = /^(?:\[\[)([^]+)(?:\]\])$/;
  const WHITESPACE = /\s/g; // matches whitespace
  const CONSTANTS_EXP = /(((\$global|\$application|\$page|\$flow)\.constants\.)|(\$constants\.))[\\$A-Z_a-z]/g; // eslint-disable-line max-len
  const VARIABLES_EXP = /(((\$global|\$application|\$page|\$flow)\.variables\.)|(\$variables\.))[\\$A-Z_a-z]/g; // eslint-disable-line max-len
  // allows [] following variable names.
  const VARIABLES_EXP2 = /((((\$global|\$application|\$page|\$flow)\.variables)|(\$variables))\[(.*?)\])/g;

  /**
   * A reusable evaluated value that can be used to capture complex expressions.
   */
  class Expression {
    /**
     * public lookup
     * @return {RegExp}
     * @constructor
     */
    static get WHITESPACE_REGEX() {
      return WHITESPACE;
    }

    /**
     * Creates a new expression that will evaluate the passed in function whenever it
     * itself is evaluated.
     *
     * An expression can be directly assigned to a variable. When a variable assigned to an
     * expression is read, the evaluated result is read.
     *
     * An expression can also be assigned to a component property and will automatically
     * updated when the expression value has changed.
     *
     * An expression is called with some context as follows:
     *
     * variables: The innermost scope's set of variables
     * application: The application object
     * container: The container object, i.e. the page object or a flow object (may be undefined)
     * action: The action object (may be undefined).
     *
     * @param expressionFunc A function to evaluate when the expression is evaluated
     * @returns {*} an expression (do not treat this as an observable!)
     */
    static create(expressionFunction) {
      return new ko.pureComputed(expressionFunction.bind(null));
    }

    /**
     * Creates an expression from a string. This string is expected to be just the expression, i.e.
     *  $variables.foo + $variables.bar
     * and not more complicated code, although we could support this in the future.
     *
     * Since the function generally runs in some context, this can be passed via the
     * injectedContextMap, which each key/value pair being injected into the function before
     * evaluation.
     *
     * @param expression
     * @param injectedContextMap A map of context objects that should be put in scope
     * @param prohibitWrites If true, will throw an exception if a variable in context is written to
     * @returns {f}
     */
    static createFromString(expression, injectedContextMap, prohibitWrites = true) {
      let funcStr = '';
      const injectedContext = [];
      if (injectedContextMap) {
        let i = 0;
        Object.keys(injectedContextMap).forEach((key) => {
          const val = injectedContextMap[key];
          funcStr += `const ${key} = injectedContext[${i}]; `;
          const injectedObj = prohibitWrites ? this.preventWritesToStructure(val, expression) : val;
          injectedContext.push(injectedObj);
          i += 1;
        });
      }
      funcStr += `return ${expression};`;

      try {
        const f = new Function('injectedContext', funcStr);
        return Expression.create(f.bind(null, injectedContext));
      } catch (e) {
        logger.error('Could not evaluate expression', expression, e);

        // The return value is expected to be a function. This makes it so that the code doesn't throw again
        return () => undefined;
      }
    }

    /**
     * Wraps the structure and throws exceptions if any of the objects are mutated. Note that we do not
     * use Object.freeze since we do not want to alter the original structures or prevent writes to them
     * outside of our usage.
     *
     * We also do not want to proactively clone the structures since (a) this is expensive and (b) does not
     * help the user fix their code.
     *
     * The motivation for doing this is to prevent random errors. If a mutation occurs within an expression
     * than that mutation will occur whenever something in the expression changes (causing that expression
     * to reevaulate). That reevaulation can occur seemingly randomly, causing the mutation to also occur
     * randomly - which is very bad!
     *
     * Dev note: this will only protect on modern browsers. That should be good enough for things to fail, and
     * we can hope that not all testing occurs on older browsers!
     *
     * @private
     * @param obj The object to write protect
     * @param expression The expression that was used
     * @returns {*}
     */
    static preventWritesToStructure(obj, expression) {
      const listener = {
        get(target, property) {
          const realValue = target[property];

          const config = Object.getOwnPropertyDescriptor(target, property);
          if (config && config.configurable === false && config.writable === false) {
            return realValue;
          }

          if (Utils.isObjectOrArray(realValue) && Utils.isPrototypeOfObject(realValue)) {
            // eslint-disable-next-line no-use-before-define
            return createProxy(realValue);
          }
          return realValue;
        },
        set() {
          throw new Error(`The expression '${expression}' has a method that has mutated a variable. This can `
            + 'lead to unexpected behavior when this expression is evaluated. Consider using '
            + 'the \'callModuleFunctionAction\' instead for a safe way of mutating variables.');
        },
      };

      const createProxy = (toProxyObj) => new Proxy(toProxyObj, listener);

      try {
        return createProxy(obj);
      } catch (e) {
        return obj; // for IE11
      }
    }

    /**
     * Return true is v starts with '{{' (or '[[') and ends with '}}' (or ']]').
     * @param v
     * @returns {boolean}
     */
    static isExpression(v) {
      return !!(typeof v === 'string' && Expression.getExpression(v));
    }

    /**
     * Return the expression string without {{ }} or [[ ]] or null if the string doesn't have {{ }} or [[ ]].
     * @param  {String} v an expression with {{ }} or [[]]
     * @return {String}   the expression without the bracket
     */
    static getExpression(v) {
      let exp;
      if (v) {
        const trimmedVal = v.trim();
        exp = ATTR_EXP.exec(trimmedVal);
        exp = exp ? exp[1] : null;
        if (!exp) {
          exp = ATTR_EXP_RO.exec(trimmedVal);
          exp = exp ? exp[1] : null;
        }
      }

      return exp;
    }

    /**
     * looks for expressions in the object/scalar, and evaluates them (invokes the function immediately).
     * This is in contrast to StateUtils.getValueOrExpression, that leaves them as variables.
     *
     * @param value the possible expression. if its a string, and does not have the expression syntax, return as-is.
     * @param scopes map of dollar-variables available to the expressions.
     *
     * @todo: the name 'scopes' is used elsewhere, but we should come up with a better name.
     * @returns {*}
     */
    static getEvaluated(value, scopes) {
      if (!value) {
        return value;
      }

      if (typeof value === 'string') {
        const expression = Expression.getExpression(value);
        if (expression) {
          const fnc = Expression.createFromString(expression, scopes);
          return fnc();
        }
      } else if (Array.isArray(value)) {
        return value.map((item) => Expression.getEvaluated(item, scopes));
      } else if (!Utils.isPrototypeOfObject(value)) {
        return value;
      } else if (Utils.isObject(value)) {
        const obj = {};
        Object.keys(value).forEach((key) => {
          obj[key] = Expression.getEvaluated(value[key], scopes);
        });
        return obj;
      }

      // if it's not an expression, just return the value as is
      return value;
    }


    /**
     * wraps in a try/catch, just in case.
     * returns the original expression if the expression definition is falsey,
     * or null if there is an exception.

     * @param expression
     * @param context optional properties that can be referenced in the expression
     * @returns {*}
     */
    static getEvaluatedSafe(expression, context = {}) {
      try {
        return expression ? Expression.getEvaluated(expression, context) : expression;
      } catch (e) {
        logger.error('unable to create expression', expression, e);
      }
      return null;
    }

    /**
     * Returns true if we have an expression and it has a 'constants' in it. See the regex above
     * for this.
     * This does not check if the expression is a pure constants expression, iow that it only
     * contains 'constants' and no other - like variables, builtins etc.
     * @param expr
     * @returns {*|boolean|String|Array|{index: number, input: string}}
     */
    static hasConstantsInExpr(expr) {
      return Expression.hasTokenInExpr(expr, CONSTANTS_EXP);
    }

    /**
     * Returns true if we have an expression and it has a 'variables' in it. See the regex above
     * for this.
     * This does not check if the expression is a pure variables expression, iow that it only
     * contains 'variables' and no other - like constants, builtins etc.
     * @param expr
     * @returns {*|boolean|String|Array|{index: number, input: string}}
     */
    static hasVariablesInExpr(expr) {
      return Expression.hasTokenInExpr(expr, VARIABLES_EXP) || Expression.hasTokenInExpr(expr, VARIABLES_EXP2);
    }

    /**
     * Returns true if regex token appears in expression
     * @param expr expression
     * @param token token to look for in expression
     * @return {*|boolean|String|RegExpMatchArray}
     * @private
     */
    static hasTokenInExpr(expr, token) {
      const strippedExpr = expr && typeof expr === 'string' && Expression.getExpression(expr);
      // strips white spaces before checking expression
      return strippedExpr && strippedExpr.replace(WHITESPACE, '').match(token);
    }
  }

  return Expression;
});


'use strict';

define('vb/private/services/endpointReference',[
  'vb/private/constants',
  'vb/private/utils',
], (Constants, Utils) => {
  /**
   * class EndpointReference
   *
   * An abstraction of the ways a service endpoint (a.k.a. operation) can be references.
   * Normally, an "endpoint" property is used, which is "[<namespace>:]<service Name>/<operation ID>",
   *   where the namespace is optional.
   *
   * When creating an SDP programmatically for some Dynamic UI cases, an tuple with { url, operationRef } may be used.
   *
   * This class is meant to:
   * - represent either in a scalable way (allow more properties in the future if needed, etc).
   * - calculate a namespace when the namespace is not specified, and a container is provided for context.
   *
   */
  class EndpointReference {
    /**
     * @typedef {Object} CallingContext
     * @property {string} extensionId - The calling context extension ID
     */

    /**
     * Finds the extension the container is being called from.
     * For fragments this returns extension of the container that is using the fragment, and
     * for all other containers it returns the extension that defined the container.
     *
     * @param {Object} container
     * @returns {String} the extension ID
     */
    static getCallingExtensionId(container) {
      if (container.getCallingContext) {
        const cc = container.getCallingContext();
        const extId = cc && cc.sourceExtension && cc.sourceExtension.id;
        return extId || container.extensionId;
      }
      return container.extensionId;
    }

    /**
     * Creates an endpoint reference from the specified arguments.
     *
     * A string id is parsed to obtain the 'namespace', 'serviceName', and 'operationId' following this
     * pseudo expression <i>[[@]namespace:]serviceName[/operationId]</i>. If not provided 'namespace' is
     * undefined while 'serviceId' and 'operationId' are both empty strings.
     *
     * An object id is expected to provide the 'url', 'serviceId', and 'operationRef' information.
     *
     * @param {(string|{url: string, serviceId: string, operationRef: string})} id
     * @param {object} [container] - typically the container which defines the scope in which the endpoint is being
     *                                 invoked.
     * @param {string} [container.extensionId] - the container's extension Id (or namespace), which is used to
     *                                           set the endpoint reference 'containerNamespace' property (defaults
     *                                           to 'base' if not informed).
     * @throws {Error} if id is neither a string nor an object
     */
    constructor(id, container) {
      if (!id) {
        throw Error('Invalid ID used for endpoint reference.');
      }

      const { extensionId = Constants.ExtensionNamespaces.BASE } = container || {};

      // the namespace of the extension/container/"scope" in which this endpoint is being used - for example, 'extA' if
      // this endpointReference is being instantiated because of an action chain in extension 'extA'. Never undefined.
      this._containerNamespace = extensionId;

      if (typeof id === 'string') {
        // parse [namespace]:serviceId/[operationId]
        // eslint-disable-next-line max-len
        const { prefix: namespace, main: serviceId = '', suffix: operationId = '' } = Utils.parseQualifiedIdentifier(id);
        if (namespace && namespace[0] === '@') {
          this._namespace = namespace.substring(1);
          this._programmatic = true;
        } else {
          this._namespace = namespace;
          this._programmatic = false;
        }
        this._serviceId = serviceId;
        this._operationId = operationId;

        // if the endpoint has a 'namespace' we need to be able to resolve it starting from the containerNamespace.
        // In case of endpoints used in fragments, the containerNamespace is not the extension that defined the fragment
        // but the extension which referenced the fragment.
        // When there is no 'namespace' provided, the scope for resolving the endpoint is the extension ('namespace')
        // of the container which uses the endpoint
        if (namespace && container) {
          const callingExtensionId = EndpointReference.getCallingExtensionId(container);
          if (callingExtensionId) {
            this._containerNamespace = callingExtensionId;
          }
        }
      } else if (id.url) {
        // if its not a string, assume it's one from JET Dyn UI - url, operationRef.
        const { url, operationRef, serviceId } = id;
        this._url = url;
        this._operationRef = operationRef;
        this._serviceId = serviceId;
      } else {
        throw new Error(`Attempt to create an invalid EndpointReference: ${JSON.stringify(id)}`);
      }
    }

    /**
     * Namespace (extension ID) of the container in which context the endpoint reference is being resolved.
     * @returns {string}
     */
    get containerNamespace() {
      return this._containerNamespace;
    }

    /**
     * @returns {string}
     */
    get url() {
      return this._url;
    }

    /**
     * @returns {string}
     */
    get operationRef() {
      return this._operationRef;
    }

    /**
     * Namespace may be undefined. It does not include potential '@' character in front of the endpoint ID.
     * @returns {string}
     */
    get namespace() {
      return this._namespace;
    }

    /**
     * Service Name
     * @returns {string}
     */
    get serviceName() {
      return this.serviceId;
    }

    get operationId() {
      return this._operationId;
    }

    /**
     * Service ID
     * @deprecated
     * @returns {string}
     */
    get serviceId() {
      return this._serviceId;
    }

    /**
     * Fully qualified Service ID. It contains everything in front of the operation ID.
     * If the endpoint reference was not created with specific namespace, passed in namespace is used.
     *
     * @param {string} [ns='base']
     * @returns {string}
     */
    getQualifiedServiceId(ns = this._containerNamespace) {
      return this.namespace ? `${this.fullNamespace}:${this.serviceName}` : `${ns}:${this.serviceName}`;
    }

    /**
     * Fully qualified Service ID including the operation ID.
     * If the endpoint reference was not created with specific namespace, passed in namespace is used.
     *
     * @param {string} [ns='container namespace']
     * @returns {string}
     */
    getQualifiedEndpointId(ns = this._containerNamespace) {
      return this.namespace
        ? `${this.fullNamespace}:${this.serviceName}/${this.operationId}`
        : `${ns}:${this.serviceName}/${this.operationId}`;
    }

    get fullNamespace() {
      return this._programmatic ? `@${this.namespace}` : this.derivedNamespace;
    }

    // never undefined: either the namespace or containerNamespace
    // useful when there is a need to obtain "the best, defined" namespace for this endpoint reference
    get derivedNamespace() {
      return this.namespace || this.containerNamespace;
    }

    /**
     * @returns {string}
     */
    toString() {
      return this._url
        ? JSON.stringify({ url: this._url, operationRef: this.operationRef, serviceId: this.serviceId })
        : this.getQualifiedEndpointId('');
    }
  }

  return EndpointReference;
});


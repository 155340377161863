'use strict';

define('vb/private/stateManagement/fragmentHolderExtensionMixin',[
  'vb/helpers/mixin',
  'vb/private/utils',
], (Mixin, Utils) => {
  /**
   * FragmentHolderExtensionMixin class is a base mixin for all container extension classes that reference fragments.
   * This would be page and layout extensions. Note: fragment extensions are not supported yet.
   */
  const FragmentHolderExtensionMixin = (superclass) => class extends superclass {
    constructor(extension, parent, path, base, className) {
      // layout doesn't have a parent since it's not part of the container hierarchy
      super(extension, parent, path, base, className);
      /**
       * instances of loaded fragments used by this container.
       * @type {Object}
       */
      this.fragments = {};
      /**
       * bridge that connects this container to the component
       * @type {null}
       * @private
       */
      this._bridge = null;
    }

    get bridge() {
      return this._bridge;
    }

    set bridge(fragmentBridge) {
      this._bridge = fragmentBridge;
    }

    /**
     * Retrieves a fragment instance for the fragment id. If a Fragment instance cannot be located this method
     * creates the Fragment instance first and then returns the fragment context. This is only called from the
     * FragmentBridge.
     * @param {string} id of the fragment
     * @param {string} name of the fragment (artifacts)
     * @param {object} params input from caller
     * @return {Promise} that resolves with a new fragment or one that has already been created
     */
    createFragment(id, name, params) {
      let fragment = this.fragments[id];
      // if name and/or params are provided then we definitely construct a fragment, otherwise return the fragment
      // that was previously created or undefined.
      if (!fragment && name) {
        return this.getFragmentClass().then((FragmentClazz) => {
          fragment = new (FragmentClazz)(id, this, undefined, name, params);
          this.fragments[id] = fragment;
          return fragment;
        });
      }

      return Promise.resolve(fragment);
    }

    getFragment(id) {
      return this.fragments[id];
    }

    /**
     * A extension page (or layout) can only reference a fragment defined in base extension. It's not possible for an
     * extension page/layout to include 'local' fragments (local to the current extension). At the moment only new
     * pages / layout (under self) in an extension can define and use local fragments.
     * Example for fragments defined under <ext-A>/.../ui/self/fragments,
     *   (1) extension page template defined under <ext-B>/.../ui/ext-A/.../page-templates.html can reference fragment
     *   from ext-A or some upstream extension
     *   (2) new page in ext-B can reference ext-A fragments or local fragments. This class is not involved for a new
     *   page
     *
     * Similarly,
     *   (3) extension layout template defined under <ext-B>/dynamicLayouts/ext-A/.../layout-x.html can
     *   reference fragment from ext-A or some other upstream extension
     *   (4) new layout in ext-B, defined under <ext-B>/dynamicLayouts/self/.../layout.html can also reference
     *   ext-A:fragment, but this class is not involved for a new layout case
     *
     * @return {Promise} that resolves with the (async) loaded class module. PackageFragmentReferenceable
     */
    getFragmentClass() {
      // to avoid cyclic dependency
      const modulePath = 'vb/private/stateManagement/packageFragmentReferenceable';
      if (!this.loadFragmentModulePromise) {
        this.loadFragmentModulePromise = Utils.getResource(modulePath);
      }

      return this.loadFragmentModulePromise;
    }

    disposeFragments() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });
    }

    /**
     * deletes a fragment from the container.
     * @param id
     */
    deleteFragment(id) {
      delete this.fragments[id];
    }
  };

  return FragmentHolderExtensionMixin;
});


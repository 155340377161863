'use strict';

define('vb/private/pathHandler',[
  'urijs/URI',
  'vb/private/constants',
  'vb/private/utils',
], (
  URI,
  Constants,
  Utils,
) => {
  // const logger = Log.getLogger('/vb/private/pathHandler');

  function startsWithDot(path) {
    return path && path[0] === Constants.RELATIVE_FOLDER_PREFIX;
  }

  /**
   * utility class to handle our import path scheme.
   * We hve defined some conventions for Service Def, Transform, and Bundle paths
   *
   * - Paths that start with a dot (.) are 'container-relative'.
   * - Paths that include a host name are 'absolute'
   * - Paths that do not start with a dot, but are not absolute, are 'application-relative'.
   *    application-relative paths are (the only paths that are) subject to requireJS mapping.
   *
   * Additionally, there are restrictions:
   *  - in some cases, typically when a Flow or Page is doing the importing, we do not allow parent folder paths
   *  in the path; that means, we do not allow double-dot (../) anywhere in the path.  This is to limit the
   *  reach of a Flo of Page into other Flows or Pages.
   *
   *  TODO: We will allow Paths to reach into flows for translations bundles.
   *
   *  TODO: this utility is initially used only by Bundles, but should be shared.
   */
  class PathHandler {
    /**
     *
     * @param filePath
     * @param containerPath
     * @param options
     * @param options.allowParent {boolean} default is false;
     * @param options.allowSelfRelative {boolean} default is true
     */
    constructor(filePath, containerPath = '', options) {
      const { allowParent, allowSelfRelative } = options;

      this.filePath = filePath;
      this.resolvedFilePath = null;

      this.allowParent = allowParent || false;

      this.allowSelfRelative = (allowSelfRelative !== false); // null/undefined means true
      this.containerPath = containerPath + (containerPath.endsWith('/') ? '' : '/');
    }

    /**
     *
     * @returns {boolean}
     */
    isAllowed() {
      if (this.filePath) {
        if (this.isContainerRelative() && !this.allowSelfRelative) {
          return false;
        }
        return (this.allowParent || this.filePath.indexOf(Constants.PARENT_FOLDER) === -1);
      }
      return false;
    }

    /**
     *
     * @returns {boolean}
     */
    isAbsolute() {
      return Utils.isAbsolutePath(this.filePath);
    }

    /**
     *
     * @returns {boolean}
     */
    isContainerRelative() {
      return this.filePath && startsWithDot(this.filePath);
    }

    /**
     * if the "path" begins with ".", do not prepend the current container requireJS path.
     * this allows containers to leverage requireJS mappings.
     *
     * this will prepend the container path if there is one, and the path isn't absolute;
     * otherwise, the paths are left as-is.
     *
     * @returns {string}
     */
    getResolvedPath() {
      if (!this.resolvedFilePath) {
        let filePath = this.filePath;
        let path;

        // check if its mapped, by seeing if requireJS gives us some new url, or just puts the base on it
        let isAbs = Utils.isAbsolutePath(filePath);

        // this function used to use requirejs.toUrl() to translate paths, but:
        // 1. there is no (good) reason that should have been necessary (event though I'm sure there was some issue...)
        // 2. that _may_ have been necessary to support path mappings that include the 'nls' path, but we can
        // state that that isn't supported (since it wouldn't be supported in JET);
        //   ex: "paths": { "foo": "somewhere/resource/nls" }
        // 3. the application bundling uses "bundles", and that causes toUrl() to return the bundle path, not the
        //  translation (js) path.
        // there may be an issue with loading the same bundle through different mappings (ojL10n gets stuck),
        // but i've only seen that during unit tests, and it would not make sense for that to happen in a real app.

        // only add the container path as a prefix if
        // a) the path is not already absolute
        // b) we allow all paths, OR the path currently starts with a 'dot', and
        // c) the container prefix isn't "/"
        if (!isAbs &&
          (this.allowParent || startsWithDot(filePath)) && this.containerPath !== Constants.PATH_SEPARATOR) {
          path = this.containerPath + filePath;
          isAbs = Utils.isAbsolutePath(path); // changed, update 'isAbs'
        } else {
          path = filePath;
        }

        // normalize now
        path = new URI(path).normalizePath().toString();

        // some final processing for non-absolute paths
        if (!isAbs) {
          // special case, there are double-dots; get the path as a requirejs path that starts with slash,
          // which requires the file extension
          if (path.indexOf(Constants.PARENT_FOLDER) !== -1) {
            const uri = new URI(path);
            // normalize here, requirejs does not handle './foo/../bar' properly, so try to simplify
            path = uri.normalizePath().toString();
          }
          // .. and if we still start with './', (try to) get rid of the './'
          if (path.startsWith(Constants.CURRENT_FOLDER)) {
            path = URI(path).normalizePath().toString();
          }
        }

        this.resolvedFilePath = path;
      }

      return this.resolvedFilePath;
    }

    static startsWithDot(path) {
      return startsWithDot(path);
    }
  }

  return PathHandler;
});


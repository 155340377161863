'use strict';

define('vbsw/private/constants',[], () => {
  const Constants = {};

  // constants for all the supported authentication type
  Constants.AuthenticationType = {
    ORACLE_CLOUD: 'oraclecloud', // default
    BASIC: 'basic',
    OAUTH: 'oauth',
    IMPLICIT: 'implicit',
    TOKEN_RELAY: 'tokenRelay',
    DIRECT: 'direct',
    NONE: 'none',
    HTTP_SIGNATURE_OCI: 'http_signature_oci',
  };

  // header for the authentication type
  Constants.AUTHENTICATION_TYPE_HEADER = 'vb-authentication-type';

  // used when new TRAP services are enabled to indicate to tokenRelayHandlerPlugin
  // what format of the token request is expected
  Constants.TRAP_2_ENABLED_HEADER = 'vb-trap-v2';

  // constants related to token relay
  Constants.TOKEN_RELAY_URL_HEADER = 'vb-token-relay-url';
  Constants.TOKEN_RELAY_AUTH_HEADER = 'vb-token-relay-authentication';

  // used when using the new V1.1 proxy; preprocessor may add this; postprocessor will prefix headers if present.
  // stripped before request.
  Constants.PROXY_HEADERNAME_HEADER = 'vb-proxy11-headers';

  // when using the proxy, all headers that are not meant for the proxy should have prefixed names
  // and when we strip headers, we should NOT remove proxy headers
  Constants.HEADER_PROXY_PREFIX = 'vb-proxy-';
  Constants.HEADER_PROXY_HEADER_PREFIX = `${Constants.HEADER_PROXY_PREFIX}header-`;

  // for 'passthrough' support: BUFP-39984
  Constants.HEADER_PASSTHROUGH = 'vb-auth-settings';

  // for 'scope' support: VBS-18763: special key that when specified in vb-info-extension header,
  // causes FA fetch plugin to inject appropriate token for accessing the RIS TRAP service
  Constants.ORASASS_SCOPE = 'scope';

  // header for specifying an alternate header name for the authorization header
  Constants.ALT_AUTHORIZATION_HEADER_NAME = 'vb-alt-authorization-header-name';

  // special header for using cached response when offline using the offline toolkit, e.g.,
  // currentuser request uses it to cache its response
  Constants.USE_CACHED_RESPONSE_WHEN_OFFLINE = 'vb-use-cached-response-when-offline';

  Constants.PATH_SEPARATOR = '/';

  /**
  * A mode to force cache lookup for index.html even when online
  */
  Constants.LOOKUP_INDEX_MODE = 'lookupIndex';

  // This is the clock skew tolerance for the token expiration time which is typically configured to be
  // 360s on the server but we will default it to 180s in order to be conservative. This value
  // can be overridden by setting the userConfig.configuration.jwtClockSkewTolerance property in the
  // app-flow.json.
  Constants.DEFAULT_CLOCK_SKEW_TOLERANCE = 180;

  return Constants;
});


'use strict';

define('vb/private/stateManagement/packagePage',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/private/stateManagement/pageInExtension',
  'vb/private/stateManagement/packageFragment',
  'vb/private/constants',
], (Mixin, PackageContainerMixin, PackageAndExtensionContainerMixin, PageInExtension, PackageFragment) => {
  /**
   * PackagePage class
   * A class for page object defined in an App UI
   */
  class PackagePage extends Mixin(PageInExtension).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, path, className = 'PackagePage') {
      super({ id, extension: parent.package.extension }, parent, path, className);
    }

    static get FragmentClass() {
      return PackageFragment;
    }
  }

  return PackagePage;
});


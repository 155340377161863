/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/events/baseEventModel',[
  'vb/private/constants',
  'vb/private/log',
], (Constants, Log) => {
  const logger = Log.getLogger('/vb/private/events/eventModel');

  // an ordered list of container classes, used for checking if an Event reference is allowed
  // TODO: follow up on layout and fragments, local events are accessible regardless of this
  // ordered list, but we will need to ensure whether layout and fragments need access to anything else
  const CLASSES = [
    'application',
    'flow',
    'page',
    'apppackage',
    'packageflow',
    'packagepage',
    'applicationextension',
    'apppackageextension',
    'flowextension',
    'pageextension',
  ];

  /**
   * A model for event definitions, both declared and undeclared.
   *
   * Along with name, defining container (if one), and other convenience properties,
   * this contains a "behavior" {EventBehavior} property.
   *
   * Any model returned from the registry extends this model.
   *
   */
  class BaseEventModel {
    /**
     * @param name
     * @param registry {EventRegistry} needed to prevent circular requireJS dependency
     * @param definition JSON declaration, if any
     * @param container {Container|null} if declared, the declaring container; otherwise null
     * @param isInterface {boolean} whether this is declared in the container's "interface" section.
     */
    constructor(name, registry, definition, container, isInterface = false) {
      this.name = name;
      Object.assign(this, definition); // cheap class for now, copies behavior & mode
      this.container = container;

      this.isDeclared = !!container; // only true if we were given a declaring container

      // the fully-qualified name, using extension namespace/prefix:
      //   <extension namespace>/<container prefix>:<simple name>
      // - extension namespace: extension ID or 'base'
      // - container prefix: 'application', 'flow', 'page', 'layout' or 'fragment'
      // - simple name: the event name, with no prefix/namespace

      if (container) {
        this.fullName = `${container.extensionId || Constants.ExtensionNamespaces.BASE}/`
          + `${BaseEventModel.basePrefix(container)}:${name}`;
      } else {
        this.fullName = name;
      }

      this.isInterface = isInterface;
    }

    /**
     * checks if the event is accessible to the container.
     * In any given context, we only have access to one of each Page / Flow / Application,
     * even though Flows can have sub-Flows (with Pages, etc).
     *
     * The registrar's scopeResolver must have the container that is asking for the registration.
     *
     * @param container
     * @param name the name used by the reference.
     * @returns {boolean} false if the name is not properly qualified or the event in not defined container scope.
     * @private
     */
    accessibleBy(container, name) {
      if (this.container.className === 'Application') {
        // everyone can access;
        return true;
      }

      let resolver = null;
      const nameParts = BaseEventModel.parseName(container, name);

      if (container.isExtension()) {
        // if properly qualified names are used, check that the name's 'ext' resolves the same
        // for the container (that we are checking for) as it resolves for the registrar
        const partsForRegistrar = BaseEventModel.parseName(this.container, this.name);
        if (nameParts.ext !== partsForRegistrar.ext) {
          return false;
        }

        // if it's not defined in the interface, check if it's defined locally in the extension:
        if (!this.isInterface) {
          resolver = container.scopeResolver;
          const scope = resolver[nameParts.prefix];
          const isLocalEvent = scope && !!scope.hasEvent(nameParts.name);

          if (!isLocalEvent) {
            return false;
          }
        }
      }

      // if the event is defined locally, then the scopeResolver is already set, otherwise when getting
      // the scopeResolver and className to check access, we want it for the 'base' container, not the extension.
      // For example, for a PageExtension, we want the Page's scopeResolver, and we look for the 'page'.
      if (!resolver) {
        const base = BaseEventModel.baseContainer(container);
        resolver = base.scopeResolver;
      }

      // the event is accessible if the scope resolver for the container that fired the event
      // has a property that is the container that is requesting the event registration,
      // AND if the requesting container is a equal or lower class of the registrar.
      // For example, a Flow's scopeResolver will have only 'application' and 'flow' (and 'this').
      // If the current container's  className.toLowerCase() is 'page', this returns false.
      // If it is 'flow', make sure the 'flow' in the resolver is the same Flow as the one asking for the event.
      return !!(resolver && resolver[this.container.eventPrefix] === this.container)
        && (CLASSES.indexOf(container.className.toLowerCase()) >= CLASSES.indexOf(this.container.eventPrefix));
    }

    /**
     * return the base container (page, flow, application).
     * @param container
     * @returns {*}
     */
    static baseContainer(container) {
      return container.base || container;
    }

    /**
     * return the base container class, as lower case, for use in event names/keys
     * @param container
     * @returns {string}
     */
    static basePrefix(container) {
      return BaseEventModel.baseContainer(container).eventPrefix;
    }

    /**
     * <'base'|''|extensionId>/<container|''>:<name>
     *
     * @param container
     * @param name
     * @returns {*|string[]|void}
     */
    static parseName(container, name) {
      const parts = name.split(':');

      let namespace;
      let simpleName;
      if (parts.length === 2) {
        [namespace, simpleName] = parts;
      } else if (parts.length === 1) {
        [simpleName] = parts;
        const extensionContext = container.extensionId || Constants.ExtensionNamespaces.BASE;
        const prefix = BaseEventModel.basePrefix(container);
        namespace = `${extensionContext}/${prefix}`;
      } else {
        logger.error('Invalid event name', name);
        return null;
      }

      const namespaceParts = namespace.split('/');

      if (namespaceParts.length === 2) {
        // assume 'base' when it starts with a slash
        if (!namespaceParts[0]) {
          namespaceParts[0] = Constants.ExtensionNamespaces.BASE;
        }
      } else if (namespaceParts.length === 1) {
        // no slash, if it has a has an extensionId, use it; otherwise, use 'base'
        const extPrefix = container.extensionId || Constants.ExtensionNamespaces.BASE;
        namespaceParts.unshift(extPrefix);
      }

      if (!namespaceParts[1]) {
        namespaceParts[1] = BaseEventModel.basePrefix(container);
      }

      return {
        ext: namespaceParts[0],
        prefix: namespaceParts[1], // container: 'application', 'page', or 'flow'
        name: simpleName,
      };
    }
  }

  return BaseEventModel;
});


'use strict';

define('vb/action/builtin/callChainAction',[
  'vb/action/action',
  'vb/private/action/actionChainUtils',
  'vb/private/utils',
  'vb/private/log',
],
(Action, ActionChainUtils, Utils, Log) => {
  /**
   * This action is used to invoke another action chain.
   */
  class CallChainAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = Log.getLogger('/vb/action/action/CallChainAction');
      this.scopes = null;
      this.context = null;
    }

    perform(parameters) {
      return new Promise((resolve, reject) => {
        const { id, chain, params } = parameters;
        const actionId = id || chain;
        const isJsChain = !!chain;

        if (!actionId) {
          this.log.error('Required \'id\' parameter missing.');
          reject();
        }

        this.log.info('Calling action chain', actionId, 'with parameters:', params);
        ActionChainUtils.startChain(actionId, params, this.context, this.scopes, isJsChain, true)
          .catch(reject)
          .then(resolve);
      });
    }

    setContext(scopes, context) {
      this.scopes = scopes;
      this.context = context;
    }
  }

  return CallChainAction;
});

